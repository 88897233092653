import { isWeb } from "./platform"
import { t } from "i18next"
import { Gender, PaymentType } from "../constants/default-options";
import { CartItem } from "./cart-item";

export const IsEmptyStr = (str: any) => {
  return !str || str === "";
}

export const IsValidEmailAddress = (address: any) => {
  return address && /\S+@\S+\.\S+/.test(address);
}

export const IsOnlyDigits = (numStr: any) => {
  return /^\d+$/.test(numStr);
}
/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Language } from '../constants/default-options';
import { IsEmptyStr } from '../utilities/field-validation';
import { GetLanguage, IsAuthed } from '../utilities/general';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class CartStore {

  loading: boolean = false;

  loadingOps: boolean = false;

  cartItemNum: number = 0;

  cartList: any = {
    totalPrice: 0, totalCommission: 0, amount: 0, productList: [],
    contactInfo: {
      contactTitle: "",
      contactFirstname: "",
      contactSurname: "",
      contactEmail: "",
      contactPhoneArea: "",
      contactPhone: ""
    }
  };

  opList: any = [];


  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingOps: observable,
      cartItemNum: observable,
      cartList: observable,
      opList: observable,
      getAgentCartList: action,
      addToCart: action,
      deleteCartItemByProductId: action,
      clearCartItems: action,
      getAgentCartWithOps: action,
      getCartItemNumber: action,

    });

    this.rootStore = rootStore;
  }

  getCartItemNumber = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/AgentCart/CountCartItem`);
      this.cartItemNum = res.data;
      console.log("Cart Number", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getAgentCartList = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/AgentCart/GetAgentCartList?language=${GetLanguage()}`);
      this.cartList = res.data;
      this.cartItemNum = res.data.productList ? res.data.productList.length : 0;
      console.log("Cart List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  addToCart = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/AgentCart/AddToCart`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getAgentCartWithOps = async () => {
    this.loadingOps = true;
    try {
      const res = await Axios.get(`/api/AgentCart/GetAgentCartWithOps?language=${GetLanguage()}`);
      this.opList = res.data.agents;
      this.cartList = {
        amount: res.data.amount,
        productList: res.data.productList,
        totalCommission: res.data.totalCommission,
        totalPrice: res.data.totalPrice,
        contactInfo: res.data.contactInfo,
      }
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingOps = false;
    }
  }

  deleteCartItemByProductId = async (productId: number) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/AgentCart/DeleteCartItemByProductId?productId=${productId}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  clearCartItems = async () => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/AgentCart/ClearCartItems`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

}

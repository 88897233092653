/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class MessageStore {

  loading: boolean = false;

  sendingEnquiry: boolean = false;

  unreadMsgList: any = [];
  
  filteredMsgList: any = [];

  msgDetail: any = [];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      sendingEnquiry: observable,
      unreadMsgList: observable,
      filteredMsgList: observable,
      msgDetail: observable,
      getUnreadMessages: action,
      filterAgentMessage: action,
      getAgentMessageDetailByMessageId: action,
      replyAgentEnquiry: action,
    });

    this.rootStore = rootStore;
  }

  getUnreadMessages = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/AgentMessage/GetUnreadAgentMessageItems`);
      this.unreadMsgList = res.data;
      console.log("Unread Msg List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  filterAgentMessage = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/AgentMessage/FilterAgentMessage`, reqModel);
      this.filteredMsgList = res.data;
      console.log("Unread Msg List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getAgentMessageDetailByMessageId = async (messageId: number) => {
    this.msgDetail = [];
    this.loading = true;
    try {
      const res = await Axios.get(`/api/AgentMessage/GetAgentMessageDetailByMessageId?messageId=${messageId}`);
      this.msgDetail = res.data;
      console.log("Msg Detail", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  replyAgentEnquiry = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/AgentMessage/replyAgentEnquiry`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  agentNewEnquiry = async (reqModel: any) => {
    this.sendingEnquiry = true;
    try {
      const res = await Axios.post(`/api/AgentMessage/AgentNewEnquiry`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.sendingEnquiry = false;
    }
  }

  setImportantByAgentMessageItemId = async (msgId: number) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/AgentMessage/SetImportantByAgentMessageItemId?messageId=${msgId}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}

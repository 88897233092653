/* eslint-disable no-unused-vars */
import { t } from 'i18next';
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Gender, Language } from '../constants/default-options';
import useStores from '../hooks/use-stores';
import { GetLanguage } from '../utilities/general';
import { Axios } from '../utilities/network';
import { isNative, isWeb } from '../utilities/platform';
import RootStore from './root-store';

interface RewardPoint {
  minimumPaymentPoint: number,
  pointDollarRate: number,
  totalPoint: number,
}

export default class UserStore {
  get isAuthed() {
    return isWeb && localStorage.USER_TOKEN != null && localStorage.USER_INFO != null && localStorage.WISHLIST != null;
  }

  set isAuthed(value) {
    console.log('Deleting token...');
    if (!value && isWeb) {
      delete localStorage.USER_TOKEN;
      delete localStorage.USER_INFO;
      delete localStorage.AGENT_ID;
      delete localStorage.WISHLIST;
    }
  }

  userToken: string | null = null;

  loading: boolean = false;
  signingIn: boolean = false;

  creditAmount: number = 0;

  agentSummary: any = { 
    countAgentProcessingOrder: 0, 
    countAgentRequestCancelOrder: 0, 
    countUnreadAgentMessages: 0,
    paymentDue: 0,
  };

  // Wishlist
  processingWishList: boolean = false;
  wishlist: any = [];
  // End WishList

  // Profile
  memberList: any = [];
  agentOpList: any = [];
  blogList: any = [];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      isAuthed: computed,
      loading: observable,
      signingIn: observable,
      userToken: observable,
      agentSummary: observable,
      creditAmount: observable,
      processingWishList: observable,
      wishlist: observable,
      memberList: observable,
      agentOpList: observable,
      blogList: observable,
      signIn: action,
      signOut: action,
      changePassword: action,
      signUp: action,
      getWishList: action,
      addDeleteAgentWishList: action,
      getSummaryData: action,
      updateAgentOpProfile: action,
      updateAgentProfile: action,
      getAgentOpList: action,
      getAgentProfile: action,
      getTotalBalance: action,
    });
    this.rootStore = rootStore;
  }


  signIn = async (userModel: any) => {
    const { bookingStore } = useStores();
    bookingStore.contactInfoData = {
      firstName: "",
      surname: "",
      gender: Gender.NOT_SELECTED,
      email: "",
      phoneArea: "",
      phone: "",
      createAccount: false,
      subscribe: true,
      password: ""
    }
    this.signingIn = true;
    try {
      const res = await Axios.post(`/api/AgentAuth/AgentLogIn`, userModel);
      const token = `Bearer ${res.data.token}`;
      const userResponseData = res.data;
      console.log(res.data);
      this.userToken = userResponseData.token;
      localStorage.USER_TOKEN = token;
      let userInfo = {
        id: userResponseData.id,
        firstName: userResponseData.firstname,
        lastName: userResponseData.surname,
        nickname: userResponseData.nickname,
        phone: userResponseData.phone,
        phoneArea: userResponseData.phoneArea,
        email: userResponseData.email,
        gender: userResponseData.gender,
        nationality: userResponseData.nationality,
        subscribe: userResponseData.subscribe,
        creditAmount: userResponseData.creditAmount,
      }
      localStorage.AGENT_ID = userResponseData.agentId;
      localStorage.USER_INFO = JSON.stringify(userInfo);
      localStorage.WISHLIST = JSON.stringify(userResponseData.wishListIdList);
      delete localStorage.CART_LIST;
      return Promise.resolve(res.data);
    } catch (err) {
      this.isAuthed = false;
      return Promise.reject(err);
    } finally {
      this.signingIn = false;
      this.loading = false;
    }
  }

  signOut = () => {
    this.isAuthed = false;
    this.userToken = null;
    window.location.href = '/';
    this.rootStore.notify(t('BYE'), "success");
  };

  signUp = async (userModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/AgentAuth/AgentLogIn`, userModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  changePassword = async (userModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/AgentAuth/ChangePassword`, userModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getTotalBalance = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/AgentAuth/GetTotalBalance`);
      this.creditAmount = res.data;
      console.log("Credit Amount", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateAgentProfile = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/AgentAuth/UpdateAgentProfile`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getAgentProfile = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/AgentAuth/GetAgentProfile`);
      console.log("Agent Profile", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getAgentOpList = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/AgentAuth/GetAgentOpList`);
      this.agentOpList = res.data;
      console.log("Agent OP List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateAgentOpProfile = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/AgentAuth/UpdateAgentOpProfile`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getSummaryData = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Summary/GetSummaryData`);
      this.agentSummary = res.data;
      console.log("Agent Summary", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getBlogList = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Blog/GetBlogList`);
      this.blogList = res.data;
      console.log("Blog", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  // ========= Wishlist ========== //
  getWishList = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/WishList/GetAgentWishList?language=${GetLanguage()}`);
      this.wishlist = res.data;
      console.log("Wishlist", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  addDeleteAgentWishList = async (productId: number) => {
    this.processingWishList = true;
    try {
      const res = await Axios.put(`/api/WishList/AddDeleteAgentWishList?tid=${productId}`);
      localStorage.WISHLIST = JSON.stringify(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.processingWishList = false;
    }
  }

  deleteWishListItemByProductId = async (productId: number) => {
    this.processingWishList = true;
    try {
      const res = await Axios.put(`/api/WishList/DeleteWishListItemByProductId?productId=${productId}`);
      localStorage.WISHLIST = JSON.stringify(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.processingWishList = false;
    }
  }



}

/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import moment from 'moment';
import { Gender, Language } from '../constants/default-options';
import { AUDDateFormat } from '../utilities/cart-item';
import { OrderContactInfo } from '../utilities/check-out';
import { IsEmptyStr } from '../utilities/field-validation';
import { GetLanguage } from '../utilities/general';
import { Axios } from '../utilities/network';
import { OpenAIAxios } from '../utilities/openAI-network';
import RootStore from './root-store';

export default class BookingStore {

  loading: boolean = false;
  loadingPayment: boolean = false;
  loadingSummaryList: boolean = false;
  loadingExcel: boolean = false;
  loadingFile: boolean = false;

  replyingChatGPT: boolean = false;

  routing: boolean = false;

  orderList: any = [];
  orderListByOp: any = [];

  statementList: any[] = [];

  bookingField: any = [];

  promotionCodeInfo: any | null = null;

  contactInfoData: OrderContactInfo = {
    firstName: "",
    surname: "",
    gender: Gender.NOT_SELECTED,
    email: "",
    phoneArea: "",
    phone: "",
    createAccount: false,
    subscribe: true,
    password: "",
  }
  bookingFieldData: any | null = [];

  // For identify the error position in booking field
  bookingFieldViewPositions: any | null = [];
  bookingFieldIdx: number = -1;
  // End Identify error position

  usedPromotionCode: string = "";
  usedRewardPoint: number = 0;

  timeslots: any = [];
  pickupOptions: any = [];
  tourCodeValidations: boolean | null = null;

  routePlanner: any = [];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingPayment: observable,
      loadingSummaryList: observable,
      loadingExcel: observable,
      loadingFile: observable,
      orderList: observable,
      orderListByOp: observable,
      bookingField: observable,
      replyingChatGPT: observable,
      routing: observable,
      promotionCodeInfo: observable,
      contactInfoData: observable,
      bookingFieldData: observable,
      bookingFieldIdx: observable,
      bookingFieldViewPositions: observable,
      usedPromotionCode: observable,
      usedRewardPoint: observable,
      timeslots: observable,
      pickupOptions: observable,
      tourCodeValidations: observable,
      statementList: observable,
      routePlanner: observable,
      searchAgentOrderByAgentId: action,
      searchAgentOrderByOpId: action,
      getBookingField: action,
      getPromotionDetail: action,
      createAgentOrderAndGetPaymentUrl: action,
      placeOrder: action,
      getBookingDetailByOrderStr: action,
      getProductScheduleByProductId: action,
      checkTourCode: action,
      generateBookingExcel: action,
      getVoucher: action,
      getInvoice: action,
      getInvoiceStatement: action,
      getInvoiceStatementList: action,
      getProductPickupByProductId: action,
      sendMessageToChatGPT: action,
    });

    this.rootStore = rootStore;
  }

  searchAgentOrderByAgentId = async (reqModel: any, skip: number, take: number) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/AgentBooking/SearchAgentOrderByAgentId?skip=${skip}&take=${take}`, reqModel);
      this.orderList = res.data;
      console.log("Order list", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  searchAgentOrderByOpId = async (reqModel: any, skip: number, take: number) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/AgentBooking/SearchAgentOrderByOpId?skip=${skip}&take=${take}`, reqModel);
      this.orderListByOp = res.data;
      console.log("Order OP list", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getBookingField = async (lang: Language, reqModel: any) => {
    this.bookingField = [];
    this.promotionCodeInfo = null;
    this.usedPromotionCode = "";
    this.usedRewardPoint = 0;
    this.loading = true;
    this.bookingFieldData = [];
    try {
      const res = await Axios.post(`/api/Booking/GetBookingField/${lang}`, reqModel);
      this.bookingField = res.data;
      console.log("Booking Detail", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getPromotionDetail = async (promotionCode: string, userId: number) => {
    this.promotionCodeInfo = null;
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Booking/GetPromotionDetail?promotionCode=${promotionCode}&userId=${userId}`);
      this.promotionCodeInfo = res.data;
      if (this.promotionCodeInfo.isValid) this.usedPromotionCode = promotionCode;
      else this.usedPromotionCode = "";
      console.log("Promotion Code", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  createAgentOrderAndGetPaymentUrl = async (reqModel: any) => {
    this.loadingPayment = true;
    try {
      const res = await Axios.post(`/api/AgentBooking/CreateAgentOrderAndGetPaymentUrl`, reqModel);
      console.log("Web Order Payment Url", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingPayment = false;
    }
  }

  placeOrder = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/AgentBooking/PlaceOrder`, reqModel);
      console.log("Place order", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getBookingDetailByOrderStr = async (orderStr: string) => {
    this.loadingSummaryList = true;
    try {
      const res = await Axios.get(`/api/AgentBooking/GetBookingDetailByOrderStr?str=${orderStr}`);
      console.log("Summary list", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingSummaryList = false;
    }
  }


  getProductScheduleByProductId = async (productId: number, departureDate: string) => {
    this.timeslots = [];
    this.pickupOptions = [];
    this.loading = true;
    try {
      const res = await Axios.get(`/api/AgentBooking/GetProductScheduleByProductId?productId=${productId}&departDate=${departureDate}`);
      this.timeslots = res.data;
      console.log("Timeslots", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  checkTourCode = async (tourCode: string) => {
    this.tourCodeValidations = null;
    if (IsEmptyStr(tourCode)) return;
    // this.loading = true;
    try {
      const res = await Axios.get(`/api/AgentCart/CheckTourCode?tourCode=${tourCode}`);
      this.tourCodeValidations = res.data;
      console.log("Check Tour Code", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      // this.loading = false;
    }
  }

  getProductPickupByProductId = async (productId: number, timeId: string, departureDate: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/AgentBooking/GetProductPickupByProductId?productId=${productId}&timeId=${timeId}&departDate=${departureDate}`);
      this.pickupOptions = res.data;
      console.log("Pick up options", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  generateBookingExcel = async (reqModel: any) => {
    this.loadingExcel = true;
    try {
      const res = await Axios.post(`/api/AgentBooking/GenerateBookingExcel`, reqModel, { responseType: 'blob' });
      const href = URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = href;
      const fileName = `${GetLanguage() === Language.ENGLISH ? 'Order History' : "订单记录"} (${moment(reqModel.startDate).format(AUDDateFormat)}-${moment(reqModel.endDate).format(AUDDateFormat)}).xls`
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href)
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingExcel = false;
    }
  }

  getVoucher = async (productId: number, username: string) => {
    this.loadingFile = true;
    try {
      const res = await Axios.get(`/api/AgentBooking/GetVoucher?userName=${username}&orderProductId=${productId}`, { responseType: 'blob' });
      const fileLink = new Blob([res.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(fileLink);
      window.open(fileURL);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingFile = false;
    }
  }

  getInvoice = async (orderId: number) => {
    this.loadingFile = true;
    try {
      const res = await Axios.get(`/api/Invoice/GetInvoice?orderId=${orderId}`, { responseType: 'blob' });
      const fileLink = new Blob([res.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(fileLink);
      window.open(fileURL);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingFile = false;
    }
  }

  getInvoiceStatementList = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Invoice/GetInvoiceStatementList`);
      this.statementList = res.data;
      console.log("Statement", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getInvoiceStatement = async (statementId: number, opId: number) => {
    this.loadingFile = true;
    try {
      const res = await Axios.get(`/api/Invoice/GetInvoiceStatement?statementIds=${statementId}&opId=${opId}`, { responseType: 'blob' });
      const fileLink = new Blob([res.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(fileLink);
      window.open(fileURL);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingFile = false;
    }
  }


  runRoutePlanner = async (reqModel: any) => {
    this.routePlanner = [];
    this.routing = true;
    try {
      const res = await Axios.post(`/api/VMRoutePlanner/RunARoutePlanner`, reqModel);
      let result = Object.keys(res.data).map((key) => res.data[key]);
      this.routePlanner = result;
      console.log("Route Planner", res.data, result);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.routing = false;
    }
  }

  sendMessageToChatGPT = async (reqModel: any) => {
    this.replyingChatGPT = true;
    try {
      const res = await OpenAIAxios.post("https://api.openai.com/v1/chat/completions", reqModel);
      console.log("Route Planner", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.replyingChatGPT = false;
    }
  }
  
  getRoutingPDF = async (reqModel: any) => {
    this.routing = true;
    try {
      const res = await Axios.post(`/api/VMRoutePlanner/GetRoutingPDF`, reqModel, { responseType: 'blob' });
      const fileLink = new Blob([res.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(fileLink);
      window.open(fileURL);
      console.log("Route Planner", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.routing = false;
    }
  }
}

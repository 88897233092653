/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Language, SearchResultType } from '../constants/default-options';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class FilterStore {

  loading: boolean = false;

  quickSearchList: any = [];

  searchOptionList: any = [
    { value: SearchResultType.REGION, childCategories: [] },
    { value: SearchResultType.TAG, childCategories: [] },
    { value: SearchResultType.PRODUCT, childCategories: [] }
  ];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      quickSearchList: observable,
      searchOptionList: observable,
      initialSearchResultOption: action,
    });

    this.rootStore = rootStore;
  }

  initialSearchResultOption = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Filter/InitialSearchResultOption`);
      this.searchOptionList = res.data;
      console.log("Initial Search Option List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  initialQuickSearch = async (language: Language) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Filter/InitialQuickSearch/${language}`);
      this.quickSearchList = res.data;
      console.log("Quick Search List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}

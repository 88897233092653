import { t } from "i18next";
import { Language, Nationality, Nationality_zh_CN, Nationality_zh_TW } from "../constants/default-options";
import useStores from "../hooks/use-stores";
import { isWeb } from "./platform";

export const IsAuthed = () => {
  return isWeb && localStorage.AGENT_ID != null && localStorage.USER_TOKEN != null && localStorage.USER_INFO != null && localStorage.WISHLIST != null;
}

export const GetLanguage = () => {
  return (isWeb && localStorage.LANG) ? localStorage.LANG : Language.ENGLISH;
}

export const GetLanguageLabel = () => {
  return (isWeb && localStorage.LANG) ? localStorage.LANG == Language.ENGLISH ? 'English'
    : localStorage.LANG == Language.ZH_HK ? '繁體中文 (香港)'
      : localStorage.LANG == Language.ZH_TW ? '繁體中文 (台灣)'
        : '简体中文' : 'English'
}

export const GetAgentId = () => {
  if (isWeb && localStorage.AGENT_ID) return localStorage.AGENT_ID;
  return 0;
}

export const GetUserProfile = () => {
  if (isWeb && localStorage.USER_INFO) return JSON.parse(localStorage.USER_INFO);
  return {
    id: 0,
    firstName: "Unknown",
    lastName: "Unknown",
    creditAmount: 0,
  };
}

export const GetCreditAmount = () => {
  if (isWeb && localStorage.USER_INFO) return JSON.parse(localStorage.USER_INFO).creditAmount.toFixed(2);
  return 0;
}

export const GetNationalityList = () => {
  if (GetLanguage() === Language.ENGLISH) return Nationality;
  else if (GetLanguage() === Language.ZH_CN) return Nationality_zh_CN;
  return Nationality_zh_TW;
}

export const ConvertPathNameToLabel = (pathName: any) => {
  return t(pathName.split('/').pop().replace('-', '_').toUpperCase());
}

export const ProdEnvCheck = (dataList: any) => {
  if (typeof window !== 'undefined') {
    for (var data of dataList) if (!data) return false;
    return true;
  }
  return false;
}



const login = {
  HI: '嗨',
  SIGN_IN: '登錄',
  SIGN_IN_NOW: '登錄',
  FORGOT_PASSWORD: '忘記密碼',
  USERNAME: '用戶名',
  PASSWORD: '密碼',
  CONFIRM_PASSWORD: '確認密碼',
  TWO_PASSWORDS_NOT_MATCH: '密碼不匹配',
  INCORRECT_USERNAME_PASSWORD: '用戶名或密碼錯誤',
  EMAIL: '郵箱',
  CONFIRM_EMAIL: '確認郵箱',
  TWO_EMAILS_NOT_MATCH: '郵箱不匹配',
  INVALID_EMAIL: '無效郵箱',
  SEND_VERIFICATION_EMAIL: '發送驗證郵件',
  VERIFICATION_EMAIL_SENT: '驗證郵件已發送',
  RESET_PASSWORD: '重置密碼',
  RESET_PASSWORD_FAILED: '重置密碼失敗',
  VERIFY_URL_LINK: '認證鏈接',
  SIGN_OUT: '登出',
  SIGN_OUT_CHECK: '確認登出',
  VALIDATING_USER_CREDENTIAL: '認證用戶信息中',
  BYE: '再見',
  SIGN_UP_SUCCESSFULLY: '申請成功',
  AUTHENTICATION_EXPIRED: '用戶登錄狀態過期',
  LOGIN: '登錄',
  SIGN_UP: '申請',
  CHECKOUT_AS_GUEST: '以遊客身份結賬',
  DONT_HAVE_ACCOUNT: "還沒有enjoysAus.com賬戶",
  CONTINUE_AS_GUEST_AND_CREATE_ACCOUNT_AFTERWARDS: "不用擔心！您可以先以遊客身份結賬付款後免費註冊一個新用戶",
  ACCOUNT_BENEFITS: '賬戶優勢',
  FAST_EZ_CHECK_OUT: '以更加快捷的方式進行結賬',
  COLLECT_REWARD_PTS: '收集您的獎勵積分',
  ACCESS_TO_ORDER_HISTORY: '回顧訂單記錄',
  CONTINUE_AS_GUEST: '以遊客身份繼續',
  CREATE_NEW_ACCOUNT_OR_LOGIN: '註冊/登錄',
  B2B_SERVICE_PLATFORM_SPECIALLY_FOR_AGENT_SERVICE: '一款致力于为代理商提供便捷服务的B2B平台系统',
  B2B_AGENTS_CAN_USE_THIS_SYSTEM_AFTER_LOGGIN: 'B2B代理人登录自己的账号后即可使用本系统',
  P2P_AGENT_SITE: 'P2P代理商平台',
  INCORRECT_EMAIL_PASSWORD: '郵箱或密碼不正確',
}

const hotPicks = {
  HOT_PICKS: '熱門精選',
  DEST: '目的地',
  PRODUCT_NAME: '產品名稱',
  FREE_CANCALLATION: '免費取消時間',
  ACTION: "操作",
  DAYS: "天",
  VIEW: "查看",
  MAKE_BOOKING: '預訂',
  ORDERS_PROCESSING: '處理訂單',
  CANCELLATION_REQUESTED: '取消請求',
  UNREAD_MSG: '未讀消息',
  PAYMENT_DUE: '到期付款',
  YOU_HAVE: '你有',
  UNREAD_MESSAGE: '未讀消息',
  By: '通過',
  DETAIL: '详情',
  BLOGS: '篇博文',
}

const allProducts = {
  CITY: '城市',
  ACTIVITIES: '門票',
  DAY_TRIPS: '一日遊',
  MULTI_DAY_TOURS: '多日遊',
  FOUND: '已找到',
  PRODUCTS: '產品',
  MORE_INFO: '更多信息',
  BOOK: '預訂',
  STEP_1_TRAVEL_DATE: '第1步 - 選擇出發日期',
  STEP_2_PRICE: '第2步 - 選擇價格選項',
  SELECTED_TRAVEL_DATE: '已選定的出發日期',
  NO_SELECTED: '未選擇出發日期',
}

const availability = {
  SUPPLIER: '供應商',
  FAST_TRACK_AVAILABILITY_ONLY_NO_BOOKINGS_TAKEN: "僅提供有效產品查詢服務,不接受預訂",
  DATE_TIME: '日期時間',
  NO_TIMESLOTS_FOUND: "未查找到數據",
}

const messagePage = {
  MESSAGE_BOX: "消息框",
  STATUS: "狀態",
  MESSAGE_TYPE: "消息類型",
  KEYWORD: "關鍵詞",
  SEARCH_FOR: "搜索...",
  START_DATE: "開始日期",
  END_DATE: "結束日期",
  SEARCH: "搜索",
  RESET: "重置",
  SEARCH_RESULT: "搜索結果",
  MESSAGE: '消息',
  UNREAD: '未讀',
  READ: '已讀',
  MARKED_IMPORTANT: "已標記",
  TYPE: '類型',
  ALL_MSG: '所有消息',
  PRODUCT_ENQUIRY: '產品諮詢',
  GENERAL_ENQUIRY: '常規諮詢',
  NEW_MESSAGE: '新消息',
  NO_MSG_FOUND: '未找到消息',
  VIEW_ENQUIRY_DETAIL: '查看諮詢詳情',
  ORDER_ENQUIRY: '訂單諮詢',
  SUBJECT: '主題',
  ENQUIRY: '諮詢',
  SEND_ENQUIRY: '發送消息',
  PLS_SELECT_A_PRODUCT: '請選擇產品',
  ENQUIRY_SENT: '消息已发送',
  MSG_LIMIT_500_CHARS: '消息限制為 500 個字符',
  VIEW_ORDER_DETAIL: '查看订单详情',
  MSG_DETAIL: '咨询详情',
}

const orderPage = {
  PURCHASE_DATE_FROM: '購買日期自',
  PURCHASE_DATE_TO: '購買日期至',
  DEPARTURE_DATE_FROM: '出發日期自',
  DEPARTURE_DATE_TO: '出發日期至',
  REF_CODE: '單號/旅行號',
  ORDER_QUANTITY: '訂單數量',
  ORDER_NO: '訂單號',
  PAYMENT_STATUS: '付款狀態',
  UNPAID: '未付',
  PAID: '已支付',
  YOUR_REFERENCE_TOUR_CODE: '您的單號/旅行號',
  NEW_ORDERS: '新訂單',
  AMEND: '修改',
  NO_ORDERS_FOUND: '未查找到訂單',
  MSG: '消息',
  READ_MORE: '查看更多',
  TOTAL_CUSTOMER: '顧客總數',
  MAIN_TRAVELLER: '主要參團人',
  MAIN_TRAVELLER_MOBILE: '主要參團人手機號',
  MAIN_TRAVELLER_EMAIL: '主要參團人郵箱',
  MAIN_CONTACT: '主要聯繫人',
  MAIN_CONTACT_EMAIL: '主要聯繫人郵箱',
  MAIN_CONTACT_MOBILE: '主要聯繫人手機號',
  BOOKING_AGENT: '預訂代理',
  PAYMENT_POLICY: '支付政策',
  PAYMENT_TYPE: '支付類型',
  TOTAL_PAID: '支付總額',
  CANCELLATION_FEE_REGULATIONS: '取消費規定',
  DATE: '日期',
  CANCELLATION_FEE: '取消費',
  UNTIL: '直到',
  PRODUCT: '產品',
  ORDERS_FOUND: '份訂單',
  IF_ENQUIRY_ABOUT_A_SPECIFIC_PRODUCT_IN_ORDER_PLS_SELECT_THE_PRODUCT_OPTIONAL: "如果您要諮詢此訂單中的某件特定產品,請從此下拉列表中選擇該產品（可選）",
  ENQUIRY_TYPE: '諮詢類型',
  PURCHASE_DATE: '购买日期',
  INSTANT_PAYMENT_REQUIRED_IN_FULL: '需要全额支付',
  VIEW_INVOICE: '查看訂單發票',
  DOWNLOAD_VOUCHER: '下載產品優惠券',
  GENERATING_FILE: '生成PDF文件中',
}

const statement = {
  NO_STATEMENTS_FOUND: "未找到數據",
  AMOUNT: '金額',
  EXPORT_EXCEL: '通過Excel導出',
  YOUR_REF: "您的單號",
  ORDER_STATEMENT: '訂單',
  INVOICE_STATEMENT: '發票'
}

const companyProfile = {
  COMPANY_NAME: '公司名稱',
  PHONE: '電話',
  CONTACT_EMAIL: '聯繫郵箱',
  WEBSITE: '網址',
  ADDRESS: '地址',
  STREET: '街道',
  SUBURB: '區',
  POSTCODE: '郵政編碼',
  STATE: '州',
  COUNTRY: '國家',
  UPDATE_PROFILE: '更新檔案信息',
  PROFILE_UPDATED: '檔案已更新',
}

const userManagement = {
  AGENT_OPS: '代理人',
  NAME: '姓名',
  ACTIVE_STATUS: '激活狀態',
  CURRENT_PASSWORD: '當前密碼',
  NEW_PASSWORD: '新密碼',
  CONFIRM_PASSWORD: '確認密碼',
  RESET_NOW: '立即重置',
  PASSWORD_UPDATED: '密碼已更新',
}

const menu = {
  HOME: '主頁',
  TRAVEL_PLANNING: '路線規劃',
  LOGIN_REGISTER: '登錄/免費註冊',
  CART: '購物車',
  MY_ORDER: '我的訂單',
  WISHLIST: '收藏夾',
  HELLO: '你好',
  SAIL_TOGETHER: '讓我們一起揚帆遠航。 ',
  TRIP_NEVER_FORGET: '您將擁有一次終生難忘的旅行。 ',
  SEARCH_BY_DEST_ATTRACTIONS_TOURS: '搜景點/目的地',
  USER_PROFILE: '用戶',
  ALL_PRODUCTS: '所有产品',
  AVAILABILITY: '产品有效性查询',
  MSGS: '消息',
  ORDERS: '订单',
  STATEMENTS: '账单',
  SEARCH_PRODUCTS: '搜索产品',
  AVA_CREDIT: '有效积分',
  COMPANY_PROFILE: '公司档案',
  USER_MANAGEMENT: '用户管理',
}

const footer = {
  POLICY: "條款",
  COMPANY: "公司",
  CONTACT_US: "聯繫我們",
  PRIVACY_POLICY: "隱私條款",
  TERMS_CONDITIONS: "條款和條件",
  ABOUT_US: "關於我們",
  HOW_TO_FIND_US: "如何找到我們",
  TERMS_CONDITION: "條款和條件",
}

const homePage = {
  NEW_PROMOTION_LIST_DESCRIPTION: '去過悉尼，那你知道在悉尼可以親手餵鯊魚嗎？去過墨爾本，那你知道那裡有澳洲最大的滑雪場嗎？去過凱恩斯，那你知道...這裡專門給你藏在深巷裡的佳釀',
  ACTIVITY: '多快好省',
  MEANINGFUL_ACTIVITIES: '跟著買手撿便宜',
  MORE: '更多',
  POPULAR_LIST_DESCRIPTION: '澳洲那麼大，好玩的地方那麼多，漂亮的景色到處是。但是又懶得做攻略，那就跟著其他旅友/高手，看看他們都去哪兒',
  FRESHLY: '深藏不露',
  HAND_PICKED: '跟著達人玩點不一樣的',
  MORE_BANG: '高手林立',
  FOR_YOUR_BUCK: '跟著旅友挑好貨'
}

const searchBar = {
  WHERE_U_GO: '去哪兒',
  WUT_U_DO: '做些什麼',
  SEARCH_FOR: '搜索',
  NO_RESULT_FOUND: '未找到結果',
  TOUR_ACTIVITIES: '旅行與活動',

}

const productListPage = {
  PRODUCT_LIST: '產品列表',
  PRODUCT_ADDED_TO_WISHLIST: '添加到收藏夾',
  PRODUCT_REMOVED_FROM_WISHLIST: '已从收藏夹中移除',
  FILTER: '篩選',
  POPULAR_DESTINATION: '目標地點',
  CATEGORY: '類別',
  LANGUAGE: '語言',
  RESULTS_FOUND: '條結果',
}

const productDetailPage = {
  PRODUCT_DETAIL: '產品信息',
  PRODUCT_NOT_RELEASED: '此產品未推出/已經售罄',
  INSTANT_CONFIRM: '立即確認',
  STANDARD_INCLUSIONS: '費用包含',
  AVA: '可選',
  FROM: '',
  FROM_B: '起',
  TRAVEL_DATE: '旅行日期',
  DEPARTURE_DATE: '出發日期',
  CHECK_AVAILABILITY: '立即查詢',
  ADD_TO_CART: '加入購物車',
  KEY_FACTS: '產品概要',
  OVERVIEW: '概覽',
  YOTPO_REVIEWS: 'Yotpo評測',
  INCLUDED: '包含',
  EXCLUDED: '不包含',
  OPERATING_HOURS: '營業時間',
  OTHER_INFO: '其他信息',
  DESTINATION: '目的地',
  HOW_TO_GET_THERE: '如何前往',
  CHECK_IN_REQUIREMENT: '兌換要求',
  ITINERARY: '行程介紹',
  PRODUCT_HAS_ADDED_TO_YOUR_CART: '產品已加入購物車',
  INVALID_TICKET_QUANTITY_SELECTED: '無效售票數量',
  MIN_QTY: '最低數量',
  MAX_QTY: '最高數量',
  PROCEED_TO_CART: '前往購物車',
  YOUR_ITEM_HAS_BEEN_ADDED: '成功加入購物車',
  NO_TICKET_OPTIONS_AVA: '售票暫無開放',
  WHAT_TO_BRING_WEAR: '建議攜帶/穿戴',
  UNAVAILABLE: '不可用',
  ONLY: '只剩',
  LEFT: '票',
}

const cartPage = {
  YOUR_CART_IS_EMPTY: '空的購物車',
  SHOPPING_CART: "購物車",
  DELETE_SELECTED_ACTIVITY: "刪除已選擇產品",
  TOTAL_COST: "合計",
  CONTINUE_SHOPPING: "繼續購物",
  PROCEED_TO_CHECKOUT: "去結賬",
  ORDER_TOTAL: '訂單總價',
  REVIEW_ORDER: '瀏覽訂單',
  SECURE_CHECKOUT: '加密付款',
  COMPLETED_ORDER: '完成訂單',
  ITEMS_REMOVED_FROM_CART: '個產品已從購物車移除',
  ALL: '全部',
  MAIN_CONTACT_EMAIL_ADDRESS_FOR_NOTIFICATIONS: '通知的主要聯繫人/電子郵件地址',
  AGENT_OP: '代理人',
  TITLE: '頭銜',
  PHONE_AREA: '國家/地區',
  MOBILE_PHONE: '手機號',
  REVIEW_CART: '瀏覽購物車',
  EMPTY_CART: '空的購物車',
  TIME: '時間',
  PICKUP_LOCATION: '接人地點',
  SPECIAL_REQUESTS: '特別要求',
  TOTAL_COMISSION: '總佣金',
  COMMISSION: "佣金",
  TOTAL_AUD: '總價AUD',
  FULL_ONLINE_PAYMENT_REQUIRED: "需要在線全額支付",
  MOVE_TO_WISHLIST: '移至收藏夾',
  REMOVE_FROM_WISHLIST: '從收藏夾中刪除',
  DELETE: '刪除',
  DELETE_ALL: '全部刪除',
  CONFIRM_AND_PAY: '確認並支付',
  AVAILABLE_CREDIT: '可用积分',
  SURCHARGE: '手續費',
  NO_SURCHARGE: '無手續費',
  PAY_NOW_SUBMIT_ORDER: '立即付款/提交訂單',
  PLS_SELECT_YOUR_AGENT_BEFORE_CHECK_OUT: '請在結帳前選擇您的代理人',
  PROCESSING: '處理中',
  ADD_TO_CART_CHECKOUT: '加入購物車/前往結賬',
  ADD_TO_CART_CONTINUE_SHOPPING: '加入購物車/繼續購物',
  SCHEDULE: '選擇時間',
  PICKUP_OPTIONS: '接送選擇',
  IS_A_NEW_REFERENCE_TOUR_CODE: '有效單號/旅行號',
  REFERENCE_TOUR_CODE_ALREADY_EXISTED: '此單號/旅行號已被使用',
  ADD_TO_CART_FAILED_PLS_TRY_AGAIN_OR_CONTACT_ADMIN: '添加到購物車失敗！請重試或者聯繫系統管理員',
  CART_CLEARED: '購物車已清空',
  CART_ITEM_DELETED: '產品已從購物車移除',
  PLS_CONFIRM_YOUR_DELETE: '是否確認刪除',
  DELETE_NOW: '刪除',
  OTHER_COUNTRIES_DISTRICT: '其他國家/地區',
}

const checkOutPage = {
  CHECK_OUT: '結賬',
  CONTACT_INFO_LEADER_TRAVELLER: '聯繫方式：主要參與人',
  GENDER: '性別',
  AREA_CODE: '國家/地區',
  NUMBER: '手機號碼',
  BOOKING_DETAILS: '訂單列表',
  SPECIAL_REQUIREMENT: '特殊要求',
  LEAD_TRAVELLER: '主要參與人',
  TRAVELLER: '參團人',
  FIRST_NAME: '名',
  SURNAME: '姓',
  MALE: '男性',
  FEMALE: '女性',
  PLS_PROVIDE_FULL_DETAILS_OF_EACH_PASSENGER: '請提供每名旅客的詳情信息',
  BONUS: '使用優惠券/積分',
  PROMOTION_CODES: '促銷代碼',
  APPLY: '使用',
  REWARD_POINTS: '獎勵積分',
  USE: '使用',
  WORTHING: '可兌換',
  MINIMUM_PAYMENT_POINT: '低於最低分數兌換限制',
  YOU_DONT_HAVE_ENOUGH_POINTS: "您沒有足夠的分數",
  REWARD_POINT_DISCOUNT: '獎勵積分折扣',
  PROMOTION_DISCOUNT: '促銷代碼折扣',
  PROMOTION_CODE_INFO: '促銷代碼信息',
  VALID_CODE: '有效代碼',
  INVALID_CODE: '無效代碼',
  PROMOTION_TYPE: '促銷方式',
  PAYMENT: '付款',
  CREDIT_CARD: '信用卡',
  POLI: 'POLi',
  PAY_NOW_PLACE_ORDER: '付款/完成訂單',
  SUMMARY: '訂單總結',
  READ_TERMS_CONDITION_POLICY: '點擊付款即代表您已經明確表示已經閱讀並接受享樂旅遊網的條款及細則，以及取消條款',
  PROCESSING_ORDER: '處理訂單中',
  WOULD_YOU_LIKE_TO_CREATE_AN_ACCOUNT: '同時建立賬戶？ ',
  YES: '是',
  NO: '否',
  AGREE_TO_SUBSCRIBE_ENJOYAUS: '訂閱Enjoyaus促銷信息',
  DEPARTURE_TIME: '出發時間',
  DEPARTURE_FLIGHT_NO: '出發航班號',
  DEPARTURE_FLIGHT_TYPE: '出發航班類型',
  RETURN_DATE: '回程日期',
  RETURN_TIME: '回程時間',
  RETURN_FLIGHT_NO: '回程航班號',
  RETURN_FLIGHT_TYPE: '回程航班類型',
  DOMESTIC: '國內航班',
  INTERNATIONAL: '國際航班',
  ONE_OR_MORE_FIELDS_IN_CONTACT_INFO_SECTION_ARE_NOT_FILLED_CORRECTLY: '請確保聯繫方式填寫完整及正確',
  ONE_OR_MORE_FIELDS_IN_BOOKING_SECTION_ARE_NOT_FILLED_CORRECTLY: '請確保訂單列表填寫完整及正確',
  INVALID_EMAIL_FORMAT: '無效郵箱地址',
  WEIGHT: '體重',
  HEIGHT: '身高',
  PLS_SELECT_YOUR_PAYMENT_METHOD: '請選擇付款方式！ ',
  SELECT: '選擇',
  BOOKING_FIELDS: '預訂詳情',
  PLEASE_DONT_LEAVE_THE_PAGE: "請不要離開此頁面",
  SELECT_PRODUCT: '選擇產品',
  TRAVELLER_DETAILS: '遊客信息',
  CONFIRM_PAY: '確認支付',
  ORDER_COMPLETED: '訂單完成',
  PROCESSING_PAYMENT: '正在處理您的付款',
}

const confirmationPage = {
  ORDER_CONFIRMATION: '訂單確認',
  CONFIRMATION: '訂單確認',
  THANK_YOU_FOR_UR_ORDER_FROM_ENJOYAUS: '感謝您的訂單',
  YOUR_ORDER_NUM_IS: '您的訂單號為',
  YOUR_VOUCHER_WILL_BE_SENT_VIA_EMAIL: '稍後請前往您的電子郵箱查收確認憑證',
  QUANTITY: '數量',
  TOTAL: '總計',
  MANAGE_MY_BOOKINGS: '管理我的訂單',
  PAYMENT_CANCELLED: '付款取消',
  PAYMENT_FAILED: '付款失敗',
  CHECK_OUT_AGAIN: '再次結賬',
  DISCOUNT: '折扣',
  THIS_PAYMENT_WAS: '不好意思！此次付款',
  CANCELLED: '已被取消',
  FAILED: '已失敗',
  U_CAN_GO_TO_CART_AND_MAKING_PAYMENT_AGAIN: '請返回購物車並嘗試重新付款',
  THANK_YOU_FOR_UR_ORDER_FROM_TOUR61: '感謝您的TOUR61訂單',
}

const wishList = {
  ADD_TO_WISHLIST: "添加到收藏夾",
  REMOVE_FROM_WISHLIST: "從收藏夾裡移除",
  LOGIN_REQUIRED: '此功能需要',
  SHOW_MORE: '展開',
  SHOW_LESS: '折疊',
}

const travelPlanningPage = {
  PRODUCE_YOUR_UNIQUE_PLAN_USING_OUR_TRAVEL_PLANNING: '使用我們的旅行計劃系統來創建您自己的獨特計劃',
  PLAN_AUTOMATION: '計劃自動化',
  FULL_CUSTOMISED_TRAVEL_PLAN: '高度自定義的旅行計劃',
  CLEAR_ROUTE_PLANNER: '提供準確的路線規劃',
  BACK_TO_PREVIOUS_TRIP_PLANNING: '返回之前的旅行計劃',
  WHAT_IS_YOUR_TRAVELLING_PURPOSES: '您旅行的目的是什麼',
  WHICH_CTS_YOU_ARE_LOOKING_TO_TRAVEL: '選擇一個城市來開啟您的旅行',
  HOW_MANY_TRIP_DAYS_YOUR_PLAN_FOR_MAX_14_DAYS: '您計劃的旅行天數（最多 14 天）',
  WHATS_YOUR_TRAVEL_DATE: '您的旅行日期',
  WHAT_IS_THE_MINUMUM_ATTRACTIONS_THAT_YOU_WANT_TO_VISIT: '請提供您要參觀最少景點數量是多少',
  WHAT_AREAS_YOU_ARE_MOSTLY_INTERESTED_ON: '在旅行中您最感興趣的領域是什麼（可多選）',
  TIP_SELECT_THE_INTERESTED_AREAS_AND_CLICK_ADD_BUTTON_ADDED_TO_THE_BELOW_AREA: '提示:選擇感興趣的區域並點擊添加按鈕添加到下方的確認區域',
  DAYS: '天',
  ATTRACTIONS: '個景點',
  NO_INTERESTED_AREA_SELECTED: '未選擇感興趣的區域',
  PLAN_THIS_TRIP: '計劃這次旅行',
  BACK_TO_YOUR_PLAN: '返回上一步',
  STEP_1_ADD_RECOMMENED_PLACES_TO_YOUR_TRIP: '將此列表中的推薦地點添加到右側的當天旅遊計劃中',
  STEP_2_EDIT_YOUR_TRIP: '編輯您這一天的旅行計劃',
  STEP_1: '第1步',
  STEP_2: '第2步',
  ADD_A_NEW_TRIP_DAY: '添加新的旅行日',
  DELETE_CURRENT_DAY: '刪除當前旅行日',
  REVERT_TRIP: '還原至初始計劃',
  ARE_YOU_SURE_IN_DELETING_THIS_TRIP_DAY: '您確定要刪除這一天的旅行安排嗎？請注意此操作將清除此計劃的預訂歷史',
  ARE_YOUR_SURE_TO_REVERT_YOUR_PLANNING_TO_DEFAULT: '您確定要將當前計劃恢復為初始計劃嗎？請注意此操作將清除此計劃的預訂歷史',
  REVERT_SUCCESS: '還原成功',
  DELETE_NOW: '立即刪除',
  REVERT_NOW: '立即恢復',
  NEW_EMPTY_TRIP_DAY: '此旅行日當前無任何安排',
  BOOKED: '已預訂',
  SET_UR_START_POINT: '設置您這一天旅行的起點',
  ROUTE_PLANNER: '路線規劃師',
  WANT_TO_BOOK: '想預訂',
  GENERATING_RECOMMENED_ROUTE: '生成推薦路線',
  TRAVEL_BY: '旅行',
  DRIVING: '駕車',
  ROUTE_SUMMARY: '路線總結',
  YOU_NEED_ADD_AT_LEAST_TWO_LOCATIONS_TO_VIEW_THE_ROUTE_FOR_CURRENT_TRIP_DAY: '您需要至少添加兩個景點才能看到當前旅行日的路線',
  ATTRACTION_ADDED: '已添加景點',
  ATTRACTION_REMOVED: "景點已移除",
  NEW_TRIP_DAY_ADDED: '添加了新的旅行日',
  TRIP_DAY_DELETE: '刪除旅行日',
  DAY_A: '第',
  DAY_B: '天',
  SAME_PLACES_ALLOWED: '允許為不同的旅行日選擇相同的景點',
  START_POINT: '起點',
  TRIP_OVERVIEW: '行程概覽',
  TALK_ABOUT_YOUR_PLAN: "談談您的計劃",
  PLAN_YOUR_TRIP_DAYS: '計劃您的旅行',
  MAKE_BOOKING: '開始預訂',
  START: '開始',
  TOTAL_DISTANCE: '總距離',
  DURATION: "行程時間",
  WAYPOINT: '航點',
  END: '結束',
  YOUR_TRIP_SUMMARY: '您的旅行計劃總結',
  LOADING: '加載中',
  STEP_1_TRAVEL_DATE: '第 1 步 - 選擇旅行日期',
  STEP_2_PRICE: '第 2 步 - 選擇價格選項',
  SELECTED_TRAVEL_DATE: '選定的旅行日期',
  NO_SELECTED: '未選擇旅行日期',
  BOOK: '預訂',
  GENERATING_SUMMARY: '總結生成中',
  GENERATING_YOUR_PERSONAL_TRAVEL_PLAN: '生成您的個人旅行計劃',
  PLEASE_BE_PATIENT: '請耐心等待',
  NOTE_THE_NUMBER_OF_TRIP_DAYS_COULD_AFFECT_GENERATING_SPEED: '注意：當您計劃的旅行天數超過7天后將可能會影響計劃生成速度',
  STEP_3_SELECT_A_PRODUCT_FROM_THE_SCHEDULED_PLACES_AND_ADD_TO_CART: '第 3 步：根據您的日程安排推薦的產品如下所示。請隨意挑選您感興趣的任何產品並將它們添加到您的購物車',
  NO_AVAILABLE_PRICE_OPTIONS_PLEASE_RETRY_WITH_ANOTHER_DATE: '當前日期的所有價格選項已售空，請通過選擇不同的旅行日期來重新獲取其他價格選項',
  FAMILY_TRIP: '家庭旅行',
  BUSINESS_TRIP: '商務旅行',
  HONEY_MOON: '蜜月旅行',
  ADELAIDE: '阿德萊德',
  BRISBANE: '布里斯班',
  CANBERRA: '堪培拉',
  CAIRNS: '凱恩斯',
  DARWIN: '達爾文',
  MELBOURNE: '墨爾本',
  PERTH: '珀斯',
  SYDNEY: '悉尼',
  TASTE_LOCAL_FOOD: '當地美食',
  AMUSEMENT_PARK: '遊樂園',
  NATURAL_ATTRACTIONS: '自然景點',
  HISTORY_ATTRACTIONS: '歷史名勝',
  ALL_RECOMMENED_ATTRACTIONS_HAS_BEEN_SELECTED: '所有推薦景點都已完成安排',
  TIP_U_CAN_TURN_ON_THE_SAME_PLACES_ALLOWED_SWITCH_ABOVE_TO_ALLOW_YOURSELF_TO_SELECT_SAME_ATTRACTIONS_FOR_DIFFERENT_TRAVEL_DAYS: '提示：您可嘗試打開上方開關以開啟景點多選功能。通過開啟此功能將允許相同景點可被安排進不同旅行日。',
  SET_AS_START_POINT: '設為行程起點',
  START_POINT_IS_SET_TO: '當天行程的起點已設置為',
  BOOKING: '預定',
  NO_PRODUCT_SELECTED: '未選擇任何產品',
  EACH_DAY_CAN_CONTAINED_MAX_8_PLACES: '每個行程日最多只能規劃8個景點',
  BACK_TO_PLANNING: '返回至行程安排頁面',
  EXPORT_AS_PDF: '通過PDF導出',
}

export default {
  translation: {
    ...login,
    ...menu,
    ...footer,
    ...homePage,
    ...searchBar,
    ...productListPage,
    ...productDetailPage,
    ...checkOutPage,
    ...confirmationPage,
    ...cartPage,
    ...wishList,
    ...hotPicks,
    ...allProducts,
    ...availability,
    ...messagePage,
    ...orderPage,
    ...statement,
    ...companyProfile,
    ...userManagement,
    ...travelPlanningPage
  }
};
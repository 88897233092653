const login = {
  HI: '嗨',
  SIGN_IN: '登录',
  SIGN_IN_NOW: '登录',
  FORGOT_PASSWORD: '忘记密码',
  USERNAME: '用户名',
  PASSWORD: '密码',
  CONFIRM_PASSWORD: '确认密码',
  TWO_PASSWORDS_NOT_MATCH: '密码不匹配',
  INCORRECT_USERNAME_PASSWORD: '用户名或密码错误',
  EMAIL: '邮箱',
  CONFIRM_EMAIL: '确认邮箱',
  TWO_EMAILS_NOT_MATCH: '邮箱不匹配',
  INVALID_EMAIL: '无效邮箱',
  SEND_VERIFICATION_EMAIL: '发送验证邮件',
  VERIFICATION_EMAIL_SENT: '验证邮件已发送',
  RESET_PASSWORD: '重置密码',
  RESET_PASSWORD_FAILED: '重置密码失败',
  VERIFY_URL_LINK: '认证链接',
  SIGN_OUT: '登出',
  SIGN_OUT_CHECK: '确认登出',
  VALIDATING_USER_CREDENTIAL: '认证用户信息中',
  BYE: '再见',
  SIGN_UP_SUCCESSFULLY: '申请成功',
  AUTHENTICATION_EXPIRED: '用户登录状态过期',
  LOGIN: '登录',
  SIGN_UP: '申请',
  CHECKOUT_AS_GUEST: '以游客身份结账',
  DONT_HAVE_ACCOUNT: "还没有enjoysAus.com账户",
  CONTINUE_AS_GUEST_AND_CREATE_ACCOUNT_AFTERWARDS: "不用担心！您可以先以游客身份结账付款后免费注册一个新用户",
  ACCOUNT_BENEFITS: '账户优势',
  FAST_EZ_CHECK_OUT: '以更加快捷的方式进行结账',
  COLLECT_REWARD_PTS: '收集您的奖励积分',
  ACCESS_TO_ORDER_HISTORY: '回顾订单记录',
  CONTINUE_AS_GUEST: '以游客身份继续',
  CREATE_NEW_ACCOUNT_OR_LOGIN: '注册/登录',
  B2B_SERVICE_PLATFORM_SPECIALLY_FOR_AGENT_SERVICE: '一款致力于为代理商提供便捷服务的B2B平台系统',
  B2B_AGENTS_CAN_USE_THIS_SYSTEM_AFTER_LOGGIN: 'B2B代理人登录自己的账号后即可使用本系统',
  P2P_AGENT_SITE: 'P2P代理商平台',
  ALL_PRODUCTS: '所有产品',
  AVAILABILITY: '有效产品查询',
  MSGS: '消息',
  ORDERS: '订单',
  STATEMENTS: '账单',
  SEARCH_PRODUCTS: '搜索产品',
  AVA_CREDIT: '有效积分',
  COMPANY_PROFILE: '公司档案',
  USER_MANAGEMENT: '用户管理',
  INCORRECT_EMAIL_PASSWORD: '邮箱或密码不正确',
}

const hotPicks = {
  HOT_PICKS: '热门精选',
  DEST: '目的地',
  PRODUCT_NAME: '产品名称',
  FREE_CANCELLATION: '免费取消时间',
  ACTION: "操作",
  DAYS: "天",
  VIEW: "查看",
  MAKE_BOOKING: '预订',
  ORDERS_PROCESSING: '处理订单',
  CANCELLATION_REQUESTED: '取消请求',
  UNREAD_MSG: '未读消息',
  PAYMENT_DUE: '到期付款',
  YOU_HAVE: '你有',
  UNREAD_MESSAGE: '未读消息',
  By: '通过',
  DETAIL: '详情',
  BLOGS: '篇博文',
}

const allProducts = {
  CITY: '城市',
  ACTIVITIES: '门票',
  DAY_TRIPS: '一日游',
  MULTI_DAY_TOURS: '多日游',
  FOUND: '已找到',
  PRODUCTS: '产品',
  MORE_INFO: '更多信息',
  BOOK: '预订',
  STEP_1_TRAVEL_DATE: '第1步 - 选择出发日期',
  STEP_2_PRICE: '第2步 - 选择价格选项',
  SELECTED_TRAVEL_DATE: '已选定的出发日期',
  NO_SELECTED: '未选择出发日期',
}

const availability = {
  SUPPLIER: '供应商',
  FAST_TRACK_AVAILABILITY_ONLY_NO_BOOKINGS_TAKEN: "仅提供有效产品查询服务,不接受预订",
  DATE_TIME: '日期时间',
  NO_TIMESLOTS_FOUND: "未查找到数据",
}

const messagePage = {
  MESSAGE_BOX: "消息框",
  STATUS: "状态",
  MESSAGE_TYPE: "消息类型",
  KEYWORD: "关键词",
  SEARCH_FOR: "搜索...",
  START_DATE: "开始日期",
  END_DATE: "结束日期",
  SEARCH: "搜索",
  RESET: "重置",
  SEARCH_RESULT: "搜索结果",
  MESSAGE: '消息',
  UNREAD: '未读',
  READ: '已读',
  MARKED_IMPORTANT: "已标记",
  TYPE: '类型',
  ALL_MSG: '所有消息',
  PRODUCT_ENQUIRY: '产品咨询',
  GENERAL_ENQUIRY: '常规咨询',
  NEW_MESSAGE: '新消息',
  NO_MSG_FOUND: '未找到消息',
  VIEW_ENQUIRY_DETAIL: '查看咨询详情',
  ORDER_ENQUIRY: '订单咨询',
  SUBJECT: '主题',
  ENQUIRY: '咨询',
  SEND_ENQUIRY: '发送消息',
  PLS_SELECT_A_PRODUCT: '请选择产品',
  ENQUIRY_SENT: '消息已发送',
  MSG_LIMIT_500_CHARS: '消息限制为 500 个字符',
  VIEW_ORDER_DETAIL: '查看订单详情',
  MSG_DETAIL: '咨询详情',
}

const orderPage = {
  PURCHASE_DATE_FROM: '购买日期自',
  PURCHASE_DATE_TO: '购买日期至',
  DEPARTURE_DATE_FROM: '出发日期自',
  DEPARTURE_DATE_TO: '出发日期至',
  REF_CODE: '单号/旅行号',
  ORDER_QUANTITY: '订单数量',
  ORDER_NO: '订单号',
  PAYMENT_STATUS: '付款状态',
  UNPAID: '未付',
  PAID: '已支付',
  YOUR_REFERENCE_TOUR_CODE: '您的单号/旅行号',
  NEW_ORDERS: '新订单',
  AMEND: '修改',
  NO_ORDERS_FOUND: '未查找到订单',
  MSG: '消息',
  READ_MORE: '查看更多',
  TOTAL_CUSTOMER: '顾客总数',
  MAIN_TRAVELLER: '主要参团人',
  MAIN_TRAVELLER_MOBILE: '主要参团人手机号',
  MAIN_TRAVELLER_EMAIL: '主要参团人邮箱',
  MAIN_CONTACT: '主要联系人',
  MAIN_CONTACT_EMAIL: '主要联系人邮箱',
  MAIN_CONTACT_MOBILE: '主要联系人手机号',
  BOOKING_AGENT: '预订代理',
  PAYMENT_POLICY: '支付政策',
  PAYMENT_TYPE: '支付类型',
  TOTAL_PAID: '支付总额',
  CANCELLATION_FEE_REGULATIONS: '取消费规定',
  DATE: '日期',
  CANCELLATION_FEE: '取消费',
  UNTIL: '直到',
  PRODUCT: '产品',
  ORDERS_FOUND: '份订单',
  IF_ENQUIRY_ABOUT_A_SPECIFIC_PRODUCT_IN_ORDER_PLS_SELECT_THE_PRODUCT_OPTIONAL: "如果您要咨询此订单中的某件特定产品,请从此下拉列表中选择该产品（可选）",
  ENQUIRY_TYPE: '咨询类型',
  PURCHASE_DATE: '购买日期',
  INSTANT_PAYMENT_REQUIRED_IN_FULL: '需要全额支付',
  VIEW_INVOICE: '查看订单发票',
  DOWNLOAD_VOUCHER: '下载产品优惠券',
  GENERATING_FILE: '生成PDF文件中',
  ERR_IN_GENERATING_FILE: '生成PDF文件失败，请稍后再试',
}

const statement = {
  NO_STATEMENTS_FOUND: "未找到数据",
  AMOUNT: '金额',
  EXPORT_EXCEL: '通过Excel导出',
  YOUR_REF: "您的单号",
  ORDER_STATEMENT: '订单',
  INVOICE_STATEMENT: '发票'
}

const companyProfile = {
  COMPANY_NAME: '公司名称',
  PHONE: '电话',
  CONTACT_EMAIL: '联系邮箱',
  WEBSITE: '网址',
  ADDRESS: '地址',
  STREET: '街道',
  SUBURB: '区',
  POSTCODE: '邮政编码',
  STATE: '州',
  COUNTRY: '国家',
  UPDATE_PROFILE: '更新档案信息',
  PROFILE_UPDATED: '档案已更新',
}

const userManagement = {
  AGENT_OPS: '代理人',
  NAME: '姓名',
  ACTIVE_STATUS: '激活状态',
  CURRENT_PASSWORD: '当前密码',
  NEW_PASSWORD: '新密码',
  CONFIRM_PASSWORD: '确认密码',
  RESET_NOW: '立即重置',
  PASSWORD_UPDATED: '密码已更新',
}

const menu = {
  HOME: '主页',
  TRAVEL_PLANNING: '路线规划',
  LOGIN_REGISTER: '登录/免费注册',
  CART: '购物车',
  MY_ORDER: '我的订单',
  WISHLIST: '收藏夹',
  HELLO: '你好',
  SAIL_TOGETHER: '让我们一起扬帆远航。',
  TRIP_NEVER_FORGET: '您将拥有一次终生难忘的旅行。',
  SEARCH_BY_DEST_ATTRACTIONS_TOURS: '搜景点/目的地',
  USER_PROFILE: '用户',
}

const footer = {
  POLICY: "条款",
  COMPANY: "公司",
  CONTACT_US: "联系我们",
  PRIVACY_POLICY: "隐私条款",
  TERMS_CONDITIONS: "条款和条件",
  ABOUT_US: "关于我们",
  HOW_TO_FIND_US: "如何找到我们",
  TERMS_CONDITION: "条款和条件",
}

const homePage = {
  NEW_PROMOTION_LIST_DESCRIPTION: '去过悉尼,那你知道在悉尼可以亲手喂鲨鱼吗？去过墨尔本,那你知道那里有澳洲最大的滑雪场吗？去过凯恩斯,那你知道...这里专门给你藏在深巷里的佳酿',
  ACTIVITY: '多快好省',
  MEANINGFUL_ACTIVITIES: '跟着买手捡便宜',
  MORE: '更多',
  POPULAR_LIST_DESCRIPTION: '澳洲那么大,好玩的地方那么多,漂亮的景色到处是。但是又懒得做攻略,那就跟着其他旅友/高手,看看他们都去哪儿',
  FRESHLY: '深藏不露',
  HAND_PICKED: '跟着达人玩点不一样的',
  MORE_BANG: '高手林立',
  FOR_YOUR_BUCK: '跟着旅友挑好货'
}

const searchBar = {
  WHERE_U_GO: '去哪儿',
  WUT_U_DO: '做些什么',
  SEARCH_FOR: '搜索',
  NO_RESULT_FOUND: '未找到结果',
  TOUR_ACTIVITIES: '旅行与活动',

}

const productListPage = {
  PRODUCT_LIST: '产品列表',
  PRODUCT_ADDED_TO_WISHLIST: '添加到收藏夹',
  PRODUCT_REMOVED_FROM_WISHLIST: '已从收藏夹中移除',
  FILTER: '筛选',
  POPULAR_DESTINATION: '目标地点',
  CATEGORY: '类别',
  LANGUAGE: '语言',
  RESULTS_FOUND: '条结果',
}

const productDetailPage = {
  PRODUCT_DETAIL: '产品信息',
  PRODUCT_NOT_RELEASED: '此产品未推出/已经售罄',
  INSTANT_CONFIRM: '立即确认',
  STANDARD_INCLUSIONS: '费用包含',
  AVA: '可选',
  FROM: '',
  FROM_B: '起',
  TRAVEL_DATE: '旅行日期',
  DEPARTURE_DATE: '出发日期',
  CHECK_AVAILABILITY: '立即查询',
  ADD_TO_CART: '加入购物车',
  KEY_FACTS: '产品概要',
  OVERVIEW: '概览',
  YOTPO_REVIEWS: 'Yotpo评测',
  INCLUDED: '包含',
  EXCLUDED: '不包含',
  OPERATING_HOURS: '营业时间',
  OTHER_INFO: '其他信息',
  DESTINATION: '目的地',
  HOW_TO_GET_THERE: '如何前往',
  CHECK_IN_REQUIREMENT: '兑换要求',
  ITINERARY: '行程介绍',
  PRODUCT_HAS_ADDED_TO_YOUR_CART: '产品已加入购物车',
  INVALID_TICKET_QUANTITY_SELECTED: '无效售票数量',
  MIN_QTY: '最低数量',
  MAX_QTY: '最高数量',
  PROCEED_TO_CART: '前往购物车',
  YOUR_ITEM_HAS_BEEN_ADDED: '成功加入购物车',
  NO_TICKET_OPTIONS_AVA: '售票暂无开放',
  WHAT_TO_BRING_WEAR: '建议携带/穿戴',
  UNAVAILABLE: '不可用',
  ONLY: '只剩',
  LEFT: '票',
}

const cartPage = {
  YOUR_CART_IS_EMPTY: '空的购物车',
  SHOPPING_CART: "购物车",
  DELETE_SELECTED_ACTIVITY: "删除已选择产品",
  TOTAL_COST: "合计",
  CONTINUE_SHOPPING: "继续购物",
  PROCEED_TO_CHECKOUT: "去结账",
  ORDER_TOTAL: '订单总价',
  REVIEW_ORDER: '浏览订单',
  SECURE_CHECKOUT: '加密付款',
  COMPLETED_ORDER: '完成订单',
  ITEMS_REMOVED_FROM_CART: '个产品已从购物车移除',
  ALL: '全部',
  MAIN_CONTACT_EMAIL_ADDRESS_FOR_NOTIFICATIONS: '通知的主要联系人/电子邮件地址',
  AGENT_OP: '代理人',
  TITLE: '头衔',
  PHONE_AREA: '国家/地区',
  MOBILE_PHONE: '手机号',
  REVIEW_CART: '浏览购物车',
  EMPTY_CART: '空的购物车',
  TIME: '时间',
  PICKUP_LOCATION: '接人地点',
  SPECIAL_REQUESTS: '特别要求',
  TOTAL_COMISSION: '总佣金',
  COMMISSION: "佣金",
  TOTAL_AUD: '总价AUD',
  FULL_ONLINE_PAYMENT_REQUIRED: "需要在线全额支付",
  MOVE_TO_WISHLIST: '移至收藏夹',
  REMOVE_FROM_WISHLIST: '从收藏夹中删除',
  DELETE: '删除',
  DELETE_ALL: '全部删除',
  CONFIRM_AND_PAY: '确认并支付',
  AVAILABLE_CREDIT: '可用积分',
  SURCHARGE: '手续费',
  NO_SURCHARGE: '无手续费',
  PAY_NOW_SUBMIT_ORDER: '立即付款/提交订单',
  PLS_SELECT_YOUR_AGENT_BEFORE_CHECK_OUT: '请在结帐前选择您的代理人',
  PROCESSING: '处理中',
  ADD_TO_CART_CHECKOUT: '加入购物车/前往结账',
  ADD_TO_CART_CONTINUE_SHOPPING: '加入购物车/继续购物',
  SCHEDULE: '选择时间',
  PICKUP_OPTIONS: '接送选择',
  IS_A_NEW_REFERENCE_TOUR_CODE: '有效单号/旅行号',
  REFERENCE_TOUR_CODE_ALREADY_EXISTED: '此单号/旅行号已被使用',
  ADD_TO_CART_FAILED_PLS_TRY_AGAIN_OR_CONTACT_ADMIN: '添加到购物车失败！请重试或者联系系统管理员',
  CART_CLEARED: '购物车已清空',
  CART_ITEM_DELETED: '产品已从购物车移除',
  PLS_CONFIRM_YOUR_DELETE: '是否确认删除',
  DELETE_NOW: '删除',
  OTHER_COUNTRIES_DISTRICT: '其他国家/地区',
}

const checkOutPage = {
  CHECK_OUT: '结账',
  CONTACT_INFO_LEADER_TRAVELLER: '联系方式: 主要参与人',
  GENDER: '性别',
  AREA_CODE: '国家/地区',
  NUMBER: '手机号码',
  BOOKING_DETAILS: '订单列表',
  SPECIAL_REQUIREMENT: '特殊要求',
  LEAD_TRAVELLER: '主要参与人',
  TRAVELLER: '参团人',
  FIRST_NAME: '名',
  SURNAME: '姓',
  MALE: '男性',
  FEMALE: '女性',
  PLS_PROVIDE_FULL_DETAILS_OF_EACH_PASSENGER: '请提供每名旅客的详情信息',
  BONUS: '使用优惠券/积分',
  PROMOTION_CODES: '促销代码',
  APPLY: '使用',
  REWARD_POINTS: '奖励积分',
  USE: '使用',
  WORTHING: '可兑换',
  MINIMUM_PAYMENT_POINT: '低于最低分数兑换限制',
  YOU_DONT_HAVE_ENOUGH_POINTS: "您没有足够的分数",
  REWARD_POINT_DISCOUNT: '奖励积分折扣',
  PROMOTION_DISCOUNT: '促销代码折扣',
  PROMOTION_CODE_INFO: '促销代码信息',
  VALID_CODE: '有效代码',
  INVALID_CODE: '无效代码',
  PROMOTION_TYPE: '促销方式',
  PAYMENT: '付款',
  CREDIT_CARD: '信用卡',
  POLI: 'POLi',
  PAY_NOW_PLACE_ORDER: '付款/完成订单',
  SUMMARY: '订单总结',
  READ_TERMS_CONDITION_POLICY: '点击付款即代表您已经明确表示已经阅读并接受享乐旅游网的条款及细则,以及取消条款',
  PROCESSING_ORDER: '处理订单中',
  WOULD_YOU_LIKE_TO_CREATE_AN_ACCOUNT: '同时建立账户？',
  YES: '是',
  NO: '否',
  AGREE_TO_SUBSCRIBE_ENJOYAUS: '订阅Enjoyaus促销信息',
  DEPARTURE_TIME: '出发时间',
  DEPARTURE_FLIGHT_NO: '出发航班号',
  DEPARTURE_FLIGHT_TYPE: '出发航班类型',
  RETURN_DATE: '回程日期',
  RETURN_TIME: '回程时间',
  RETURN_FLIGHT_NO: '回程航班号',
  RETURN_FLIGHT_TYPE: '回程航班类型',
  DOMESTIC: '国内航班',
  INTERNATIONAL: '国际航班',
  ONE_OR_MORE_FIELDS_IN_CONTACT_INFO_SECTION_ARE_NOT_FILLED_CORRECTLY: '请确保联系方式填写完整及正确',
  ONE_OR_MORE_FIELDS_IN_BOOKING_SECTION_ARE_NOT_FILLED_CORRECTLY: '请确保订单列表填写完整及正确',
  INVALID_EMAIL_FORMAT: '无效邮箱地址',
  WEIGHT: '体重',
  HEIGHT: '身高',
  PLS_SELECT_YOUR_PAYMENT_METHOD: '请选择付款方式！',
  SELECT: '选择',
  BOOKING_FIELDS: '预订详情',
  PLEASE_DONT_LEAVE_THE_PAGE: "请不要离开此页面",
  SELECT_PRODUCT: '选择产品',
  TRAVELLER_DETAILS: '游客信息',
  CONFIRM_PAY: '确认支付',
  ORDER_COMPLETED: '订单完成',
  PROCESSING_PAYMENT: '正在处理您的付款',
}

const confirmationPage = {
  ORDER_CONFIRMATION: '订单确认',
  CONFIRMATION: '订单确认',
  THANK_YOU_FOR_UR_ORDER_FROM_ENJOYAUS: '感谢您的订单',
  YOUR_ORDER_NUM_IS: '您的订单号为',
  YOUR_VOUCHER_WILL_BE_SENT_VIA_EMAIL: '稍后请前往您的电子邮箱查收确认凭证',
  QUANTITY: '数量',
  TOTAL: '总计',
  MANAGE_MY_BOOKINGS: '管理我的订单',
  PAYMENT_CANCELLED: '付款取消',
  PAYMENT_FAILED: '付款失败',
  CHECK_OUT_AGAIN: '再次结账',
  DISCOUNT: '折扣',
  THIS_PAYMENT_WAS: '不好意思！此次付款',
  CANCELLED: '已被取消',
  FAILED: '已失败',
  U_CAN_GO_TO_CART_AND_MAKING_PAYMENT_AGAIN: '您可以返回购物车再次尝试付款',
  THANK_YOU_FOR_UR_ORDER_FROM_TOUR61: '感谢您的TOUR61订单',
}

const wishList = {
  ADD_TO_WISHLIST: "添加到收藏夹",
  REMOVE_FROM_WISHLIST: "从收藏夹里移除",
  LOGIN_REQUIRED: '此功能需要',
  SHOW_MORE: '展开',
  SHOW_LESS: '折叠',
}

const travelPlanningPage = {
  PRODUCE_YOUR_UNIQUE_PLAN_USING_OUR_TRAVEL_PLANNING: '使用我们的旅行计划系统来创建您自己的独特计划',
  PLAN_AUTOMATION: '计划自动化',
  FULL_CUSTOMISED_TRAVEL_PLAN: '高度自定义的旅行计划',
  CLEAR_ROUTE_PLANNER: '提供准确的路线规划',
  BACK_TO_PREVIOUS_TRIP_PLANNING: '返回之前的旅行计划',
  WHAT_IS_YOUR_TRAVELLING_PURPOSES: '您旅行的目的是什么',
  WHICH_CTS_YOU_ARE_LOOKING_TO_TRAVEL: '选择一个城市来开启您的旅行',
  HOW_MANY_TRIP_DAYS_YOUR_PLAN_FOR_MAX_14_DAYS: '您计划的旅行天数（最多 14 天）',
  WHATS_YOUR_TRAVEL_DATE: '您的旅行日期',
  WHAT_IS_THE_MINUMUM_ATTRACTIONS_THAT_YOU_WANT_TO_VISIT: '请提供您要参观最少景点数量是多少',
  WHAT_AREAS_YOU_ARE_MOSTLY_INTERESTED_ON: '在旅行中您最感兴趣的领域是什么（可多选）',
  TIP_SELECT_THE_INTERESTED_AREAS_AND_CLICK_ADD_BUTTON_ADDED_TO_THE_BELOW_AREA: '提示:选择感兴趣的区域并点击添加按钮添加到下方的确认区域',
  DAYS: '天',
  ATTRACTIONS: '个景点',
  NO_INTERESTED_AREA_SELECTED: '未选择感兴趣的区域',
  PLAN_THIS_TRIP: '计划这次旅行',
  BACK_TO_YOUR_PLAN: '返回上一步',
  STEP_1_ADD_RECOMMENED_PLACES_TO_YOUR_TRIP: '将此列表中的推荐地点添加到右侧的当天旅游计划中',
  STEP_2_EDIT_YOUR_TRIP: '编辑您这一天的旅行计划',
  STEP_1: '第1步',
  STEP_2: '第2步',
  ADD_A_NEW_TRIP_DAY: '添加新的旅行日',
  DELETE_CURRENT_DAY: '删除当前旅行日',
  REVERT_TRIP: '还原至初始计划',
  ARE_YOU_SURE_IN_DELETING_THIS_TRIP_DAY: '您确定要删除这一天的旅行安排吗？ 请注意此操作将清除此计划的预订历史',
  ARE_YOUR_SURE_TO_REVERT_YOUR_PLANNING_TO_DEFAULT: '您确定要将当前计划恢复为初始计划吗？ 请注意此操作将清除此计划的预订历史',
  REVERT_SUCCESS: '还原成功',
  DELETE_NOW: '立即删除',
  REVERT_NOW: '立即恢复',
  NEW_EMPTY_TRIP_DAY: '此旅行日当前无任何安排',
  BOOKED: '已预订',
  SET_UR_START_POINT: '设置您这一天旅行的起点',
  ROUTE_PLANNER: '路线规划师',
  WANT_TO_BOOK: '想预订',
  GENERATING_RECOMMENED_ROUTE: '生成推荐路线',
  TRAVEL_BY: '旅行',
  DRIVING: '驾车',
  ROUTE_SUMMARY: '路线总结',
  YOU_NEED_ADD_AT_LEAST_TWO_LOCATIONS_TO_VIEW_THE_ROUTE_FOR_CURRENT_TRIP_DAY: '您需要至少添加两个景点才能看到当前旅行日的路线',
  ATTRACTION_ADDED: '已添加景点',
  ATTRACTION_REMOVED: "景点已移除",
  NEW_TRIP_DAY_ADDED: '添加了新的旅行日',
  TRIP_DAY_DELETE: '删除旅行日',
  DAY_A: '第',
  DAY_B: '天',
  SAME_PLACES_ALLOWED: '允许为不同的旅行日选择相同的景点',
  START_POINT: '起点',
  TRIP_OVERVIEW: '行程概览',
  TALK_ABOUT_YOUR_PLAN: "谈谈您的计划",
  PLAN_YOUR_TRIP_DAYS: '计划您的旅行',
  MAKE_BOOKING: '开始预订',
  START: '开始',
  TOTAL_DISTANCE: '总距离',
  DURATION: "行程时间",
  WAYPOINT: '航点',
  END: '结束',
  YOUR_TRIP_SUMMARY: '您的旅行计划总结',
  LOADING: '加载中',
  STEP_1_TRAVEL_DATE: '第 1 步 - 选择旅行日期',
  STEP_2_PRICE: '第 2 步 - 选择价格选项',
  SELECTED_TRAVEL_DATE: '选定的旅行日期',
  NO_SELECTED: '未选择旅行日期',
  BOOK: '预订',
  GENERATING_SUMMARY: '总结生成中',
  GENERATING_YOUR_PERSONAL_TRAVEL_PLAN: '生成您的个人旅行计划',
  PLEASE_BE_PATIENT: '请耐心等待',
  NOTE_THE_NUMBER_OF_TRIP_DAYS_COULD_AFFECT_GENERATING_SPEED: '注意：当您计划的旅行天数超过7天后将可能会影响计划生成速度',
  STEP_3_SELECT_A_PRODUCT_FROM_THE_SCHEDULED_PLACES_AND_ADD_TO_CART: '第 3 步：根据您的日程安排推荐的产品如下所示。请随意挑选您感兴趣的任何产品并将它们添加到您的购物车',
  NO_AVAILABLE_PRICE_OPTIONS_PLEASE_RETRY_WITH_ANOTHER_DATE: '当前日期的所有价格选项已售空，请通过选择不同的旅行日期来重新获取其他价格选项',
  FAMILY_TRIP: '家庭旅行',
  BUSINESS_TRIP: '商务旅行',
  HONEY_MOON: '蜜月旅行',
  ADELAIDE: '阿德莱德',
  BRISBANE: '布里斯班',
  CANBERRA: '堪培拉',
  CAIRNS: '凯恩斯',
  DARWIN: '达尔文',
  MELBOURNE: '墨尔本',
  PERTH: '珀斯',
  SYDNEY: '悉尼',
  TASTE_LOCAL_FOOD: '当地美食',
  AMUSEMENT_PARK: '游乐园',
  NATURAL_ATTRACTIONS: '自然景点',
  HISTORY_ATTRACTIONS: '历史名胜',
  ALL_RECOMMENED_ATTRACTIONS_HAS_BEEN_SELECTED: '所有推荐景点都已完成安排',
  TIP_U_CAN_TURN_ON_THE_SAME_PLACES_ALLOWED_SWITCH_ABOVE_TO_ALLOW_YOURSELF_TO_SELECT_SAME_ATTRACTIONS_FOR_DIFFERENT_TRAVEL_DAYS: '提示：您可尝试打开上方开关以开启景点多选功能。通过开启此功能将允许相同景点可被安排进不同旅行日。',
  SET_AS_START_POINT: '设为行程起点',
  START_POINT_IS_SET_TO: '当天行程的起点已设置为',
  BOOKING: '预定',
  NO_PRODUCT_SELECTED: '未选择任何产品',
  EACH_DAY_CAN_CONTAINED_MAX_8_PLACES: '每个行程日最多只能规划8个景点',
  BACK_TO_PLANNING: '返回至行程安排页面',
  EXPORT_AS_PDF: '通过PDF导出',
}

export default {
  translation: {
    ...login,
    ...menu,
    ...footer,
    ...homePage,
    ...searchBar,
    ...productListPage,
    ...productDetailPage,
    ...checkOutPage,
    ...confirmationPage,
    ...cartPage,
    ...wishList,
    ...hotPicks,
    ...allProducts,
    ...availability,
    ...messagePage,
    ...orderPage,
    ...statement,
    ...companyProfile,
    ...userManagement,
    ...travelPlanningPage,
  }
};




const login = {
  HI: 'Hi',
  SIGN_IN: 'Sign In',
  SIGN_IN_NOW: 'Sign In Now',
  FORGOT_PASSWORD: 'Forgot Password',
  USERNAME: 'Username',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Your Password',
  TWO_PASSWORDS_NOT_MATCH: 'Passwords do not match',
  INCORRECT_USERNAME_PASSWORD: 'Incorrect Username/Password',
  EMAIL: 'Email',
  CONFIRM_EMAIL: 'Confirm Email',
  TWO_EMAILS_NOT_MATCH: 'Emails Do Not Match',
  INVALID_EMAIL: 'Invalid Email',
  SEND_VERIFICATION_EMAIL: 'Send Verification Email',
  VERIFICATION_EMAIL_SENT: 'Verification Email Sent',
  RESET_PASSWORD: 'Reset Password',
  RESET_PASSWORD_FAILED: 'Reset Password Failed',
  VERIFY_URL_LINK: 'Verify URL Link',
  SIGN_OUT: 'Sign Out',
  SIGN_OUT_CHECK: 'Are you wish to you sign out now',
  VALIDATING_USER_CREDENTIAL: 'Validating User Credentials',
  BYE: 'Bye',
  SIGN_UP_SUCCESSFULLY: 'Sign up successful',
  AUTHENTICATION_EXPIRED: 'Authentication expired',
  LOGIN: 'Login',
  SIGN_UP: 'Sign Up',
  CHECKOUT_AS_GUEST: 'Check out as guest',
  DONT_HAVE_ACCOUNT: "Don't have a enjoyaus.com account",
  CONTINUE_AS_GUEST_AND_CREATE_ACCOUNT_AFTERWARDS: "Don't worry! You can complete your checkout as aguest and choose to create an account afterwards",
  ACCOUNT_BENEFITS: 'Account Benefits',
  FAST_EZ_CHECK_OUT: 'Fast and easy checkout',
  COLLECT_REWARD_PTS: 'Collect reward points',
  ACCESS_TO_ORDER_HISTORY: 'Access to order history',
  CONTINUE_AS_GUEST: 'Continue As Guest',
  CREATE_NEW_ACCOUNT_OR_LOGIN: 'Create new account or login',
  B2B_SERVICE_PLATFORM_SPECIALLY_FOR_AGENT_SERVICE: 'B2B Service Platform, Specially for Agent Service',
  B2B_AGENTS_CAN_USE_THIS_SYSTEM_AFTER_LOGGIN: 'B2B agents can use this system after logging in their own accounts',
  P2P_AGENT_SITE: 'P2P Agent Site',
  ALL_PRODUCTS: 'All Products',
  AVAILABILITY: 'Availability',
  MSGS: 'Messages',
  ORDERS: 'Orders',
  STATEMENTS: 'Statements',
  SEARCH_PRODUCTS: 'Search Products',
  AVA_CREDIT: 'My Credit',
  COMPANY_PROFILE: 'Company Profile',
  USER_MANAGEMENT: 'User Management',
  INCORRECT_EMAIL_PASSWORD: 'Incorrect email/password',
}

const hotPicks = {
  HOT_PICKS: 'Hot Picks',
  DEST: 'Destination',
  PRODUCT_NAME: 'Product Name',
  FREE_CANCELLATION: 'Free Cancellation',
  ACTION: 'Action',
  DAYS: 'Days',
  VIEW: 'View',
  MAKE_BOOKING: 'Make Booking',
  ORDERS_PROCESSING: 'Processing Orders',
  CANCELLATION_REQUESTED: 'Cancellation Requested',
  UNREAD_MSG: 'Unread Messages',
  PAYMENT_DUE: 'Payment Due',
  YOU_HAVE: 'You have',
  UNREAD_MESSAGE: 'unread message(s)',
  BY: 'By',
  DETAIL: 'Detail',
  MSG_STATUS_TO: 'Message status changed to ',
  NOT_MARKED: 'Not Marked',
  MARKED: 'Marked',
  BLOGS: 'Blogs',
}

const allProducts = {
  CITY: 'Cities',
  ACTIVITIES: 'Activities',
  DAY_TRIPS: 'Day Trips',
  MULTI_DAY_TOURS: 'Multi-Day Tours',
  FOUND: 'Found',
  PRODUCTS: 'Products',
  MORE_INFO: 'More Info',
  BOOK: 'Book',
  STEP_1_TRAVEL_DATE: 'Step 1 - Select Travel Date',
  STEP_2_PRICE: 'Step 2 - Pick Price Options',
  SELECTED_TRAVEL_DATE: 'Selected Travel Date',
  NO_SELECTED: 'No Travel Date Selected',
}

const availability = {
  SUPPLIER: 'Suppliers',
  FAST_TRACK_AVAILABILITY_ONLY_NO_BOOKINGS_TAKEN: 'Fast Track Availability Only, No Bookings Taken',
  DATE_TIME: 'Date Time',
  NO_TIMESLOTS_FOUND: 'No Timeslots Found',
}

const messagePage = {
  MESSAGE_BOX: "Message Box",
  STATUS: "Status",
  MESSAGE_TYPE: "Message Type",
  KEYWORD: "Keyword",
  SEARCH_FOR: "Search for...",
  START_DATE: "Start Date",
  END_DATE: "End Date",
  SEARCH: "Search",
  RESET: "Reset",
  SEARCH_RESULT: "Search Result",
  MESSAGE: 'Message',
  UNREAD: 'Unread',
  READ: 'Read',
  MARKED_IMPORTANT: 'Marked Important',
  TYPE: 'Type',
  ALL_MSG: 'All Messages',
  PRODUCT_ENQUIRY: 'Product Enquiry',
  GENERAL_ENQUIRY: 'General Enquiry',
  NEW_MESSAGE: 'New Message',
  NO_MSG_FOUND: 'No Message Found',
  VIEW_ENQUIRY_DETAIL: 'View Enquiry Detail',
  ORDER_ENQUIRY: 'Order Enquiry',
  SUBJECT: 'Subject',
  ENQUIRY: 'Enquiry',
  SEND_ENQUIRY: 'Send Enquiry',
  PLS_SELECT_A_PRODUCT: 'Please select a product',
  ENQUIRY_SENT: 'Enquiry sent',
  MSG_LIMIT_500_CHARS: 'Message are limited to 500 characters',
  VIEW_ORDER_DETAIL: 'View Order Detail',
  MSG_DETAIL: 'Message Detail',
}

const orderPage = {
  PURCHASE_DATE_FROM: 'Purchase Date From',
  PURCHASE_DATE_TO: 'Purchase Date To',
  DEPARTURE_DATE_FROM: 'Departure Date From',
  DEPARTURE_DATE_TO: 'Departure Date To',
  REF_CODE: 'Your Reference/Tour Code',
  ORDER_QUANTITY: 'Order Quantity',
  ORDER_NO: 'Order No.',
  PAYMENT_STATUS: 'Payment Status',
  UNPAID: 'Unpaid',
  PAID: 'Paid',
  YOUR_REFERENCE_TOUR_CODE: 'Your Reference Tour Code',
  NEW_ORDERS: 'New Orders',
  AMEND: 'Amend',
  NO_ORDERS_FOUND: 'No Orders Found',
  MSG: 'Message',
  READ_MORE: 'Read More',
  TOTAL_CUSTOMER: 'Total Customers',
  MAIN_TRAVELLER: 'Main Traveller',
  MAIN_TRAVELLER_MOBILE: 'Main Traveller Mobile',
  MAIN_TRAVELLER_EMAIL: 'Main Traveller Email',
  MAIN_CONTACT: 'Main Contact',
  MAIN_CONTACT_EMAIL: 'Main Contact Email',
  MAIN_CONTACT_MOBILE: 'Main Contact Mobile',
  BOOKING_AGENT: 'Booking Agent',
  PAYMENT_POLICY: 'Payment Policy',
  PAYMENT_TYPE: 'Payment Type',
  TOTAL_PAID: 'Total Paid',
  CANCELLATION_FEE_REGULATIONS: 'Cancellation Fee Regulations',
  DATE: 'Date',
  CANCELLATION_FEE: 'Cancellation Fee',
  UNTIL: 'Until',
  PRODUCT: 'Product',
  ORDERS_FOUND: 'Orders Found',
  IF_ENQUIRY_ABOUT_A_SPECIFIC_PRODUCT_IN_ORDER_PLS_SELECT_THE_PRODUCT_OPTIONAL: 'If you are enquiry about a specific product in this order, please select that product from this dropdown (OPTIONAL)',
  ENQUIRY_TYPE: 'Enquiry Type',
  PURCHASE_DATE: 'Purchase Date',
  INSTANT_PAYMENT_REQUIRED_IN_FULL: 'Instance payment required in full',
  VIEW_INVOICE: 'View Order Invoice',
  DOWNLOAD_VOUCHER: 'Download Product Voucher',
  GENERATING_FILE: 'Generating PDF file',
  ERR_IN_GENERATING_FILE: 'Error in generating PDF file, please try again later',
}

const statement = {
  NO_STATEMENTS_FOUND: 'No Statements Found',
  AMOUNT: 'Amount',
  EXPORT_EXCEL: 'Export Excel',
  YOUR_REF: 'Your Ref.',
  ORDER_STATEMENT: 'Order Statement',
  INVOICE_STATEMENT: 'Invoice Statement'
}

const companyProfile = {
  COMPANY_NAME: 'Company Name',
  PHONE: 'Phone',
  CONTACT_EMAIL: 'Contact Email',
  WEBSITE: 'Website',
  ADDRESS: 'Address',
  STREET: 'Street',
  SUBURB: 'Suburb',
  POSTCODE: 'Postcode',
  STATE: 'State',
  COUNTRY: 'Country',
  UPDATE_PROFILE: 'Update Profile',
  PROFILE_UPDATED: 'Profile updated',
}

const userManagement = {
  AGENT_OPS: 'Agent Ops',
  NAME: 'Name',
  ACTIVE_STATUS: 'Activte Status',
  CURRENT_PASSWORD: 'Current Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  RESET_NOW: 'Reset Now',
  PASSWORD_UPDATED: 'Password updated',
}

const menu = {
  HOME: 'Home',
  TRAVEL_PLANNING: 'Travel Planning',
  LOGIN_REGISTER: 'Login/Register',
  CART: 'Cart',
  MY_ORDER: 'My Order',
  WISHLIST: 'Wishlist',
  HELLO: 'HELLO',
  SAIL_TOGETHER: 'Let us sail together.',
  TRIP_NEVER_FORGET: 'You will have a trip that you will never forget.',
  SEARCH_BY_DEST_ATTRACTIONS_TOURS: 'Search by destinations, attractions or tours',
  USER_PROFILE: 'User Profile',
}

const footer = {
  POLICY: "Policy",
  COMPANY: "Company",
  CONTACT_US: "Contact Us",
  PRIVACY_POLICY: "Privacy Policy",
  TERMS_CONDITIONS: "Terms & Conditions",
  ABOUT_US: "About Us",
  HOW_TO_FIND_US: "How to Find Us",
  TERMS_CONDITION: "Terms Condition",
}

const homePage = {
  NEW_PROMOTION_LIST_DESCRIPTION: 'We are delighted to launch a series of exciting new tours which include even more incredible experiences',
  ACTIVITY: 'Activity',
  MEANINGFUL_ACTIVITIES: 'Meaningful Activties',
  MORE: 'More',
  POPULAR_LIST_DESCRIPTION: 'These popular tours will have you discovering new cultures with new firends',
  FRESHLY: 'Freshly',
  HAND_PICKED: 'Hand Picked',
  MORE_BANG: 'More Bang',
  FOR_YOUR_BUCK: 'For Your Buck'
}

const searchBar = {
  WHERE_U_GO: 'Where You Go',
  WUT_U_DO: 'What You Do',
  SEARCH_FOR: 'Search for',
  NO_RESULT_FOUND: 'No Result Found',
  TOUR_ACTIVITIES: 'Tour & Activities',

}

const productListPage = {
  PRODUCT_LIST: 'Product List',
  PRODUCT_ADDED_TO_WISHLIST: 'Product added to wishlist',
  PRODUCT_REMOVED_FROM_WISHLIST: 'Product removed from wishlist',
  FILTER: 'Filter',
  POPULAR_DESTINATION: 'Popular Destination',
  CATEGORY: 'Category',
  LANGUAGE: 'Language',
  RESULTS_FOUND: 'Result(s) Found',
}

const productDetailPage = {
  PRODUCT_DETAIL: 'Product Detail',
  PRODUCT_NOT_RELEASED: 'This product is sold out or not released yet',
  INSTANT_CONFIRM: 'Instant Confirm',
  STANDARD_INCLUSIONS: 'Standard Inclusions',
  AVA: 'Available',
  FROM: 'From',
  FROM_B: '',
  TRAVEL_DATE: 'Travel Date',
  DEPARTURE_DATE: 'Departure Date',
  CHECK_AVAILABILITY: 'Check Availability',
  ADD_TO_CART: 'Add To Cart',
  KEY_FACTS: 'Key Facts',
  OVERVIEW: 'Overview',
  YOTPO_REVIEWS: 'Yotpo Reviews',
  INCLUDED: 'Included',
  EXCLUDED: 'Excluded',
  OPERATING_HOURS: 'Operating Hours',
  OTHER_INFO: 'Other Info',
  DESTINATION: 'Destination',
  HOW_TO_GET_THERE: 'How To Get There',
  CHECK_IN_REQUIREMENT: 'Check In Requirement',
  ITINERARY: 'Itinerary',
  PRODUCT_HAS_ADDED_TO_YOUR_CART: 'Product has added to your cart',
  INVALID_TICKET_QUANTITY_SELECTED: 'Invalid ticket quantity selected',
  MIN_QTY: 'Minimum Quantity',
  MAX_QTY: 'Maximum Quantity',
  PROCEED_TO_CART: 'Proceed To Cart',
  YOUR_ITEM_HAS_BEEN_ADDED: 'Your item has been added',
  NO_TICKET_OPTIONS_AVA: 'No ticket options available',
  WHAT_TO_BRING_WEAR: 'What To Bring And Wear',
  UNAVAILABLE: 'Unavailable',
  ONLY: 'Only',
  LEFT: 'Ticket Left',
}

const cartPage = {
  YOUR_CART_IS_EMPTY: 'Your Cart is Empty',
  SHOPPING_CART: "Shopping Cart",
  DELETE_SELECTED_ACTIVITY: "Delete Selected Activity",
  TOTAL_COST: "Total Cost",
  CONTINUE_SHOPPING: "Continue Shopping",
  PROCEED_TO_CHECKOUT: "Proceed to Checkout",
  ORDER_TOTAL: 'Order Total',
  REVIEW_ORDER: 'Review Order',
  SECURE_CHECKOUT: 'Secure Checkout',
  COMPLETED_ORDER: 'Completed Order',
  ITEMS_REMOVED_FROM_CART: 'Item(s) is removed from the cart',
  ALL: 'All',
  MAIN_CONTACT_EMAIL_ADDRESS_FOR_NOTIFICATIONS: 'Main Contact/Email Address for Notifications',
  AGENT_OP: 'Agent OP',
  TITLE: 'Title',
  PHONE_AREA: 'Phone Area',
  MOBILE_PHONE: 'Mobile Phone',
  REVIEW_CART: 'Review Cart',
  EMPTY_CART: 'Empty Cart',
  TIME: 'Time',
  PICKUP_LOCATION: 'Pickup Location',
  SPECIAL_REQUESTS: 'Special Requests',
  TOTAL_COMISSION: 'Total Commission',
  COMMISSION: 'Commission',
  TOTAL_AUD: 'Total AUD',
  FULL_ONLINE_PAYMENT_REQUIRED: 'Full Online Payment Required',
  MOVE_TO_WISHLIST: 'Move to Wishlist',
  REMOVE_FROM_WISHLIST: 'Remove from Wishlist',
  DELETE: 'Delete',
  DELETE_ALL: 'Delete All',
  CONFIRM_AND_PAY: 'Confirm and Pay',
  AVAILABLE_CREDIT: 'Available Credit',
  SURCHARGE: 'Surcharge',
  NO_SURCHARGE: 'No Surcharge',
  PAY_NOW_SUBMIT_ORDER: 'Pay Now/Submit Order',
  PLS_SELECT_YOUR_AGENT_BEFORE_CHECK_OUT: 'Please select your agent before check out',
  PROCESSING: 'Processing',
  ADD_TO_CART_CHECKOUT: 'Add to Cart & Checkout',
  ADD_TO_CART_CONTINUE_SHOPPING: 'Add to Cart & Continue Shopping',
  SCHEDULE: 'Schedule Time',
  PICKUP_OPTIONS: 'Pickup Options',
  IS_A_NEW_REFERENCE_TOUR_CODE: 'This is a new reference/tour code',
  REFERENCE_TOUR_CODE_ALREADY_EXISTED: 'This reference/tour code has already been used',
  ADD_TO_CART_FAILED_PLS_TRY_AGAIN_OR_CONTACT_ADMIN: 'Add to cart failed! Please try again or contact admin',
  CART_CLEARED: 'Cart cleared',
  CART_ITEM_DELETED: 'Cart item deleted',
  PLS_CONFIRM_YOUR_DELETE: 'Please confirm your delete',
  DELETE_NOW: 'Delete Now',
  OTHER_COUNTRIES_DISTRICT: 'Other Countries/Districts',
}

const checkOutPage = {
  CHECK_OUT: 'Check Out',
  CONTACT_INFO_LEADER_TRAVELLER: 'Contact Info: Lead Traveller',
  GENDER: 'Gender',
  AREA_CODE: 'Area',
  NUMBER: 'Number',
  BOOKING_DETAILS: 'Booking Details',
  SPECIAL_REQUIREMENT: 'Special Requirement',
  LEAD_TRAVELLER: 'Lead Traveller',
  TRAVELLER: 'Traveller',
  FIRST_NAME: 'First Name',
  SURNAME: 'Surname',
  MALE: 'Male',
  FEMALE: 'Female',
  PLS_PROVIDE_FULL_DETAILS_OF_EACH_PASSENGER: 'Please provide full details of each passenger',
  BONUS: 'Bonus',
  PROMOTION_CODES: 'Promotion Codes',
  APPLY: 'Apply',
  REWARD_POINTS: 'Reward Points',
  USE: 'Use',
  WORTHING: 'Worthing',
  MINIMUM_PAYMENT_POINT: 'Minimum exchange point',
  YOU_DONT_HAVE_ENOUGH_POINTS: "You don't have enough points",
  REWARD_POINT_DISCOUNT: 'Reward Points Discount',
  PROMOTION_DISCOUNT: 'Promotion Code Discount',
  PROMOTION_CODE_INFO: 'Promotion Code Info',
  VALID_CODE: 'Valid Code',
  INVALID_CODE: 'Invalid Code',
  PROMOTION_TYPE: 'Promotion Type',
  PAYMENT: 'Payment',
  CREDIT_CARD: 'Credit Card',
  POLI: 'POLi',
  PAY_NOW_PLACE_ORDER: 'Pay Now/Place Order',
  SUMMARY: 'Summary',
  READ_TERMS_CONDITION_POLICY: 'By clicking Pay Now, you agree that you have read and understood our Terms & Conditions and Cancellation Policy',
  PROCESSING_ORDER: 'Processing Order',
  WOULD_YOU_LIKE_TO_CREATE_AN_ACCOUNT: 'Would you like to create an account?',
  YES: 'Yes',
  NO: 'No',
  AGREE_TO_SUBSCRIBE_ENJOYAUS: 'Agree to subscribe to EnjoyAus',
  DEPARTURE_TIME: 'Departure Time',
  DEPARTURE_FLIGHT_NO: 'Departure Flight No',
  DEPARTURE_FLIGHT_TYPE: 'Departure Flight Type',
  RETURN_DATE: 'Return Date',
  RETURN_TIME: 'Return Time',
  RETURN_FLIGHT_NO: 'Return Flight No',
  RETURN_FLIGHT_TYPE: 'Return Flight Type',
  DOMESTIC: 'Domestic',
  INTERNATIONAL: 'International',
  ONE_OR_MORE_FIELDS_IN_CONTACT_INFO_SECTION_ARE_NOT_FILLED_CORRECTLY: 'One or more fields in CONTACT INFO section are not fill in or filled in correctly',
  ONE_OR_MORE_FIELDS_IN_BOOKING_SECTION_ARE_NOT_FILLED_CORRECTLY: 'One or more fields in BOOKING DETAILS section are not fill in or filled in correctly',
  INVALID_EMAIL_FORMAT: 'Invalid email format',
  WEIGHT: 'Weight',
  HEIGHT: 'Height',
  PLS_SELECT_YOUR_PAYMENT_METHOD: 'Please select your payment method first!',
  SELECT: 'Select',
  BOOKING_FIELDS: 'Booking Fields',
  PLEASE_DONT_LEAVE_THE_PAGE: "Please don't leave the page",
  SELECT_PRODUCT: 'Select Product',
  TRAVELLER_DETAILS: 'Traveller Details',
  CONFIRM_PAY: 'Confirm Pay',
  ORDER_COMPLETED: 'Order Completed',
  PROCESSING_PAYMENT: 'Processing your payment',
}

const confirmationPage = {
  ORDER_CONFIRMATION: 'Order Confirmation',
  CONFIRMATION: 'Confirmation',
  THANK_YOU_FOR_UR_ORDER_FROM_ENJOYAUS: 'Thank you for your order from EnjoyAus',
  YOUR_ORDER_NUM_IS: 'Your order number is',
  YOUR_VOUCHER_WILL_BE_SENT_VIA_EMAIL: 'Your voucher will be sent via email',
  QUANTITY: 'Quantity',
  TOTAL: 'Total',
  MANAGE_MY_BOOKINGS: 'Manage My Bookings',
  PAYMENT_CANCELLED: 'Payment Cancelled',
  PAYMENT_FAILED: 'Payment Failed',
  CHECK_OUT_AGAIN: 'Check Out Again',
  DISCOUNT: 'Discount',
  THIS_PAYMENT_WAS: 'Sorry! This payment was',
  CANCELLED: 'cancelled',
  FAILED: 'failed',
  U_CAN_GO_TO_CART_AND_MAKING_PAYMENT_AGAIN: 'You can go to your cart and try making payment again',
  THANK_YOU_FOR_UR_ORDER_FROM_TOUR61: 'Thank you for your order from TOUR61',
}

const wishList = {
  ADD_TO_WISHLIST: "Add to Wishlist",
  REMOVE_FROM_WISHLIST: "Remove from Wishlist",
  LOGIN_REQUIRED: 'Login Required',
  SHOW_MORE: 'Show More',
  SHOW_LESS: 'Show Less',
}


const travelPlanningPage = {
  PRODUCE_YOUR_UNIQUE_PLAN_USING_OUR_TRAVEL_PLANNING: 'Use our travel planning system to create your own unique plan',
  PLAN_AUTOMATION: 'Plan automation',
  FULL_CUSTOMISED_TRAVEL_PLAN: 'Fully customised travel planning',
  CLEAR_ROUTE_PLANNER: 'Provides an accurate route planner',
  BACK_TO_PREVIOUS_TRIP_PLANNING: 'Back to Previous Trip Planning',
  WHAT_IS_YOUR_TRAVELLING_PURPOSES: 'What is the purpose of your trip',
  WHICH_CTS_YOU_ARE_LOOKING_TO_TRAVEL: 'Select a city to begin your journey',
  HOW_MANY_TRIP_DAYS_YOUR_PLAN_FOR_MAX_14_DAYS: 'How many days you plan to travel (up to 14 days)',
  WHATS_YOUR_TRAVEL_DATE: 'What is your travel date',
  WHAT_IS_THE_MINUMUM_ATTRACTIONS_THAT_YOU_WANT_TO_VISIT: 'What is the minimum number of attractions that you want to visit',
  WHAT_AREAS_YOU_ARE_MOSTLY_INTERESTED_ON: 'What is the areas you are most interested in (can be more than one)',
  TIP_SELECT_THE_INTERESTED_AREAS_AND_CLICK_ADD_BUTTON_ADDED_TO_THE_BELOW_AREA: 'Tip: Select the area of interest and click the Add button to add to the confirmation area below',
  DAYS: 'Day(s)',
  ATTRACTIONS: 'Attractions',
  NO_INTERESTED_AREA_SELECTED: 'No interested area(s) selected',
  PLAN_THIS_TRIP: 'Plan this Trip',
  BACK_TO_YOUR_PLAN: 'Go Back to Talk About Plan',
  STEP_1_ADD_RECOMMENED_PLACES_TO_YOUR_TRIP: 'Add recommended places from this list to the day plan on the right',
  STEP_2_EDIT_YOUR_TRIP: 'Edit your plan for this travel day',
  STEP_1: 'Step 1',
  STEP_2: 'Step 2',
  ADD_A_NEW_TRIP_DAY: 'Add an new travel day',
  DELETE_CURRENT_DAY: 'Delete current travel day',
  REVERT_TRIP: 'Revert this trip plan',
  ARE_YOU_SURE_IN_DELETING_THIS_TRIP_DAY: 'Are you sure you want to delete this travel day? Please note this action will CLEAR THE BOOKING HISTORY for this plan',
  ARE_YOUR_SURE_TO_REVERT_YOUR_PLANNING_TO_DEFAULT: 'Are you sure to revert the current plan to default? Please note this action will CLEAR THE BOOKING HISTORY for this plan',
  REVERT_SUCCESS: 'Revert success',
  DELETE_NOW: 'Delete Now',
  REVERT_NOW: 'Revert Now',
  NEW_EMPTY_TRIP_DAY: 'Empty Travel Day',
  BOOKED: 'Booked',
  SET_UR_START_POINT: 'Set your starting point for this travel day',
  ROUTE_PLANNER: 'Route Planner',
  WANT_TO_BOOK: 'Want to Book',
  GENERATING_RECOMMENED_ROUTE: 'Generating Recommened Route',
  TRAVEL_BY: 'Travel by',
  DRIVING: 'Driving',
  ROUTE_SUMMARY: 'Route Summary',
  YOU_NEED_ADD_AT_LEAST_TWO_LOCATIONS_TO_VIEW_THE_ROUTE_FOR_CURRENT_TRIP_DAY: 'You need to add at least two attractions to see the route for the current travel day',
  ATTRACTION_ADDED: 'Attraction added',
  ATTRACTION_REMOVED: 'Attraction removed',
  NEW_TRIP_DAY_ADDED: 'New travel day added',
  TRIP_DAY_DELETE: 'Travel day removed',
  DAY_A: 'Day ',
  DAY_B: '',
  SAME_PLACES_ALLOWED: 'Allows to select the same attraction for different travel days',
  START_POINT: 'Start Point',
  TRIP_OVERVIEW: 'Trip Overview',
  TALK_ABOUT_YOUR_PLAN: 'Talk About Your Plan',
  PLAN_YOUR_TRIP_DAYS: 'Plan You Trip',
  MAKE_BOOKING: 'Make Booking',
  START: 'Start',
  TOTAL_DISTANCE: 'Total Distance',
  DURATION: 'Duration',
  WAYPOINT: 'Waypoint',
  END: 'End',
  YOUR_TRIP_SUMMARY: 'Your Trip Plan Summary',
  LOADING: 'Loading',
  STEP_1_TRAVEL_DATE: 'Step 1 - Select Travel Date',
  STEP_2_PRICE: 'Step 2 - Pick Price Options',
  SELECTED_TRAVEL_DATE: 'Selected Travel Date',
  NO_SELECTED: 'No Travel Date Selected',
  BOOK: 'Book',
  GENERATING_SUMMARY: 'Generating Summary',
  GENERATING_YOUR_PERSONAL_TRAVEL_PLAN: 'Generating your personal travel plan',
  PLEASE_BE_PATIENT: 'Please be patient',
  NOTE_THE_NUMBER_OF_TRIP_DAYS_COULD_AFFECT_GENERATING_SPEED: 'Note: When the number of travel days you plan exceeds 7 days, it may affect the speed of plan generation',
  STEP_3_SELECT_A_PRODUCT_FROM_THE_SCHEDULED_PLACES_AND_ADD_TO_CART: 'Step 3: Recommended products based on your schedule are shown below. Feel free to pick any products you are interested in and add them to your shopping cart',
  NO_AVAILABLE_PRICE_OPTIONS_PLEASE_RETRY_WITH_ANOTHER_DATE: 'No available price options, please select a different travel day and try again',
  FAMILY_TRIP: 'Family Trip',
  BUSINESS_TRIP: 'Business Trip',
  HONEY_MOON: 'Honey Moon',
  ADELAIDE: 'Adelaide',
  BRISBANE: 'Brisbane',
  CANBERRA: 'Canberra',
  CAIRNS: 'Cairns',
  DARWIN: 'Darwin',
  MELBOURNE: 'Melbourne',
  PERTH: 'Perth',
  SYDNEY: 'Sydney',
  TASTE_LOCAL_FOOD: 'Local Food',
  AMUSEMENT_PARK: 'Amusement Park',
  NATURAL_ATTRACTIONS: 'Natural Attractions',
  HISTORY_ATTRACTIONS: 'Historical Attractions',
  ALL_RECOMMENED_ATTRACTIONS_HAS_BEEN_SELECTED: 'All recommened attractions has been allocated',
  TIP_U_CAN_TURN_ON_THE_SAME_PLACES_ALLOWED_SWITCH_ABOVE_TO_ALLOW_YOURSELF_TO_SELECT_SAME_ATTRACTIONS_FOR_DIFFERENT_TRAVEL_DAYS: 'Tips: You can turn on the switch above to enable the function of choosing the same attractions on different travel days',
  SET_AS_START_POINT: 'Set as starting point',
  START_POINT_IS_SET_TO: 'The start point of the current trip day is set to',
  BOOKING: 'Booking',
  NO_PRODUCT_SELECTED: 'No Product Selected',
  EACH_DAY_CAN_CONTAINED_MAX_8_PLACES: 'Each trip day can only plan a maximum of 8 attractions',
  BACK_TO_PLANNING: 'Back to Planning',
  EXPORT_AS_PDF: 'Export as PDF',
}

export default {
  translation: {
    ...login,
    ...menu,
    ...footer,
    ...homePage,
    ...searchBar,
    ...productListPage,
    ...productDetailPage,
    ...checkOutPage,
    ...confirmationPage,
    ...cartPage,
    ...wishList,
    ...hotPicks,
    ...allProducts,
    ...availability,
    ...messagePage,
    ...orderPage,
    ...statement,
    ...companyProfile,
    ...userManagement,
    ...travelPlanningPage,
  }
};




import moment from "moment";
import { PromotionCodeType } from "../constants/default-options";
import useStores from "../hooks/use-stores";
import { isWeb } from "./platform"

export const AUDateTimeFormat = "DD/MM/YYYY hh:mm A";
export const AUDDateFormat = "DD/MM/YYYY";

// Single Cart Item
export interface CartItem {
  priceName?: string,
  processingTime: string,
  productId: number,
  productName: string,
  quantities: any,
  totalQuantity: any[],
  startDate: string,
  scheduleTime?: string,
  subTotal: string,
  webImage: string,
  isInstantConfirm: boolean,
  time?: string,
}

// Item List Obj
export interface CartItemList {
  totalPrice: number,
  productList: CartItem[],
}

// Add Cart Item Obj
export interface AddCartItem {
  productId: number,
  departDate: string,
  language: string,
  time?: string,
  timeId?: string,
  firstname: string,
  surname: string,
  email: string,
  phone: string,
  referenceCode: string,
  specialRequirement: string,
  qty: any[],
  pickupId?: string,
  hotel?: string,
  title: string,
  phonearea: string,
}

export const IsExpiredProduct = (departureDate: string, scheduleTime: string) => {
  return moment().isAfter(departureDate + `${scheduleTime ? 'T' + scheduleTime : ""}`);
}
export const CalculateCartItemTotalPrice = (carItemList: CartItem[]) => {
  return carItemList.filter((c: any) => !IsExpiredProduct(c.startDate.split('T')[0], c.scheduleTime))
    .reduce((prev: any, next: any) => prev + next.subTotal, 0).toFixed(2);
}
export const CalculateCartItemTotalPriceForPromotion = (carItemList: CartItem[], type: PromotionCodeType, targetId: number, deduction: number) => {
  let result: number = 0;
  carItemList.filter((c: any) => !IsExpiredProduct(c.startDate.split('T')[0], c.scheduleTime))
    .map((p: any) => {
      if (targetId != p.productId) result += p.subTotal;
      else {
        if (type === PromotionCodeType.DISCOUNT) result += p.subTotal * deduction;
        else if (type === PromotionCodeType.AMOUNT) result += p.subTotal - deduction;
      }
    })
  return result.toFixed(2);
}
export const GetCartItemNumAsGuest = () => {
  const { cartStore } = useStores();
  if (isWeb && localStorage.CART_LIST) cartStore.cartItemNum = JSON.parse(localStorage.CART_LIST).productList.length;
}

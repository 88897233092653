exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-all-products-index-tsx": () => import("./../../../src/pages/all-products/index.tsx" /* webpackChunkName: "component---src-pages-all-products-index-tsx" */),
  "component---src-pages-availability-index-tsx": () => import("./../../../src/pages/availability/index.tsx" /* webpackChunkName: "component---src-pages-availability-index-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cart-confirmation-tsx": () => import("./../../../src/pages/cart/confirmation.tsx" /* webpackChunkName: "component---src-pages-cart-confirmation-tsx" */),
  "component---src-pages-cart-index-tsx": () => import("./../../../src/pages/cart/index.tsx" /* webpackChunkName: "component---src-pages-cart-index-tsx" */),
  "component---src-pages-hot-picks-index-tsx": () => import("./../../../src/pages/hot-picks/index.tsx" /* webpackChunkName: "component---src-pages-hot-picks-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-message-index-tsx": () => import("./../../../src/pages/message/index.tsx" /* webpackChunkName: "component---src-pages-message-index-tsx" */),
  "component---src-pages-message-message-detail-tsx": () => import("./../../../src/pages/message/message-detail.tsx" /* webpackChunkName: "component---src-pages-message-message-detail-tsx" */),
  "component---src-pages-orders-index-tsx": () => import("./../../../src/pages/orders/index.tsx" /* webpackChunkName: "component---src-pages-orders-index-tsx" */),
  "component---src-pages-product-detail-tsx": () => import("./../../../src/pages/product-detail.tsx" /* webpackChunkName: "component---src-pages-product-detail-tsx" */),
  "component---src-pages-search-products-tsx": () => import("./../../../src/pages/search-products.tsx" /* webpackChunkName: "component---src-pages-search-products-tsx" */),
  "component---src-pages-setting-company-profile-tsx": () => import("./../../../src/pages/setting/company-profile.tsx" /* webpackChunkName: "component---src-pages-setting-company-profile-tsx" */),
  "component---src-pages-setting-user-management-tsx": () => import("./../../../src/pages/setting/user-management.tsx" /* webpackChunkName: "component---src-pages-setting-user-management-tsx" */),
  "component---src-pages-setting-wishlist-tsx": () => import("./../../../src/pages/setting/wishlist.tsx" /* webpackChunkName: "component---src-pages-setting-wishlist-tsx" */),
  "component---src-pages-statements-index-tsx": () => import("./../../../src/pages/statements/index.tsx" /* webpackChunkName: "component---src-pages-statements-index-tsx" */),
  "component---src-pages-travel-planning-booking-list-tsx": () => import("./../../../src/pages/travel-planning/booking-list.tsx" /* webpackChunkName: "component---src-pages-travel-planning-booking-list-tsx" */),
  "component---src-pages-travel-planning-index-tsx": () => import("./../../../src/pages/travel-planning/index.tsx" /* webpackChunkName: "component---src-pages-travel-planning-index-tsx" */),
  "component---src-pages-travel-planning-trip-view-tsx": () => import("./../../../src/pages/travel-planning/trip-view.tsx" /* webpackChunkName: "component---src-pages-travel-planning-trip-view-tsx" */)
}


export const isProduction = process.env.NODE_ENV === 'production';

const prodBackEndUrl = 'https://agentapi.tour61.com'; // 'https://mp.tour61.com.au'
const prodFrontEndUrl = 'https://agentapi.tour61.com'; // 'http://www.tourx.com.au'

export const backEndBaseURL = isProduction ? prodBackEndUrl : 'http://192.168.15.7:45461/'//'http://localhost:52012'
export const frontEndBaseUrl = isProduction ? prodFrontEndUrl : 'http://localhost:3000';

export const googleMapApiKey = "AIzaSyD6710nxRY5KzD5rfHOjaFFE_diB7b8AQA";

export const encryptedPassword = "*ENCRYPTEDPASSWORD*"; 
export const CHECK_OUT_PAYMENT_METHOD_KEYWORD = "paymentMethod=";
export const CHECK_OUT_ID_KEYWORD = "&id=";

export const GPT_MODEL = "gpt-3.5-turbo";//use gpt-4 in the future
export const chatGPTKey = "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";// Steve's free key
import React from 'react';
import { enableStaticRendering } from 'mobx-react';
import RootStore from './root-store';
import UserStore from './user-store';
import ProductStore from './product-store';
import FilterStore from './filter-store';
import CartStore from './cart-store';
import BookingStore from './booking-store';
import MessageStore from './message-store';

type CompositeStore = {
  rootStore: RootStore,
  userStore: UserStore,
  messageStore: MessageStore,
  productStore: ProductStore,
  filterStore: FilterStore,
  cartStore: CartStore,
  bookingStore: BookingStore,
};

const isServer = typeof window === 'undefined';
// eslint-disable-next-line react-hooks/rules-of-hooks
enableStaticRendering(isServer);

// let _stores: null | CompositeStore = null;

const initStores = () => {
  const rootStore = new RootStore();
  const initializedStores: CompositeStore = {
    rootStore,
    userStore: rootStore.userStore,
    productStore: rootStore.productStore,
    messageStore: rootStore.messageStore,
    filterStore: rootStore.filterStore,
    cartStore: rootStore.cartStore,
    bookingStore: rootStore.bookingStore,
  };
  return initializedStores;
};

export const stores = initStores(); // _stores || initStores();

export const StoreContext = React.createContext(stores);

export const StoreProvider = ({ children }) => <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>;

/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Language, ProductType, SearchCategory } from '../constants/default-options';
import { GetAgentId, GetLanguage } from '../utilities/general';
import { Axios } from '../utilities/network';
import RootStore from './root-store';


export default class ProductStore {

  loading: boolean = false;
  loadingDetail: boolean = false;
  loadingDateRange: boolean = false;
  checkingAva: boolean = false;
  loadingSupplier: boolean = false;
  loadingProductBySupplier: boolean = false;
  loadingCities: boolean = false;
  loadingSearch: boolean = false;
  loadingBookings: boolean = false;

  hotPickList: any = [];

  productDateRange: any = [];

  productDetail: any | null = null;

  avaInfo: any | null = null;

  avaListBySupplier: any = { rate: [], timeslots: [] };

  supplierList: any = [];

  productListBySupplier: any = [];

  filterItemList: any = [];
  allProductList: any = [];
  initialSearchList: any = [];

  searchList: any = [];
  groupListByBooking: any = [];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingDetail: observable,
      loadingDateRange: observable,
      loadingSearch: observable,
      loadingBookings: observable,
      checkingAva: observable,
      loadingSupplier: observable,
      loadingProductBySupplier: observable,
      loadingCities: observable,
      hotPickList: observable,
      productDateRange: observable,
      productDetail: observable,
      avaInfo: observable,
      avaListBySupplier: observable,
      supplierList: observable,
      productListBySupplier: observable,
      allProductList: observable,
      initialSearchList: observable,
      searchList: observable,
      groupListByBooking: observable,
      getHotPicks: action,
      getProductDateRange: action,
      checkAvailability: action,
      checkAvailabilityBySupplier: action,
      getProductDetailById: action,
      getSuppliers: action,
      getProductsBySupplierCode: action,
      searchAgentProductListByCategory: action,
      initialSearch: action,
    });

    this.rootStore = rootStore;
  }

  getHotPicks = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/AgentProduct/GetHotPicks?language=${GetLanguage()}`);
      this.hotPickList = res.data;
      console.log("Hot Pick List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getProductDateRange = async (productId: number) => {
    this.productDateRange = [];
    this.loadingDateRange = true;
    try {
      const res = await Axios.get(`/api/AgentProduct/GetProductDateRange?productId=${productId}`);
      this.productDateRange = res.data.dateRange;
      console.log("Product " + productId + " date range ", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingDateRange = false;
    }
  }

  checkAvailability = async (reqModel: any) => {
    this.avaInfo = null;
    this.checkingAva = true;
    try {
      const res = await Axios.post(`/api/AgentProduct/CheckAvailability`, reqModel);
      this.avaInfo = res.data;
      console.log("Check availability", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.checkingAva = false;
    }
  }

  checkAvailabilityBySupplier = async (reqModel: any) => {
    this.checkingAva = true;
    try {
      const res = await Axios.post(`/api/AgentProduct/CheckAvailabilityBySupplier`, reqModel);
      this.avaListBySupplier = res.data;
      console.log("Check availability", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.checkingAva = false;
    }
  }


  getProductDetailById = async (productId: number) => {
    this.productDetail = null;
    this.loadingDetail = true;
    try {
      const res = await Axios.get(`/api/AgentProduct/GetProductDetailById?productId=${productId}&language=${GetLanguage()}`);
      this.productDetail = res.data;
      console.log("Product Detail", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingDetail = false;
    }
  }

  getSuppliers = async () => {
    this.loadingSupplier = true;
    try {
      const res = await Axios.get(`/api/Supplier/GetSuppliers`);
      this.supplierList = res.data;
      console.log("Supplier List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingSupplier = false;
    }
  }

  getProductsBySupplierCode = async (supplierCode: string) => {
    this.loadingProductBySupplier = true;
    try {
      const res = await Axios.get(`/api/AgentProduct/GetProductsBySupplierCode?supplierCode=${supplierCode}`);
      this.productListBySupplier = res.data;
      console.log("Product List by Supplier", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingProductBySupplier = false;
    }
  }

  searchAgentProductListByCategory = async (value: ProductType, searchKey?: string) => {
    this.loading = true;
    const searchKeyPath = searchKey ? `?keyword=${searchKey}&` : `?`;
    try {
      const res = await Axios.get(`/api/AgentProduct/SearchAgentProductByCategory${searchKeyPath}category=${SearchCategory.PRODUCT_TYPE}&value=${value}&language=${GetLanguage()}`);
      this.allProductList = res.data.items;
      this.filterItemList = res.data.filterItems;
      console.log("All product list", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  initialSearch = async () => {
    this.loadingCities = true;
    try {
      const res = await Axios.get(`/api/AgentProduct/InitialSearch`);
      this.initialSearchList = res.data;
      console.log("Initial Search", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingCities = false;
    }
  }

  filterAgentProductByKeyword = async (searchKey: string, take: number, skip: number, searchTerm?: string) => {
    if(searchTerm) this.loadingBookings = true;
    else this.loadingSearch = true;
    try {
      const res = await Axios.get(`/api/AgentProduct/FilterAgentProductByKeyword?keyword=${searchKey}&take=${take}&skip=${skip}&language=${GetLanguage()}`);
      if (searchTerm) {
        this.groupListByBooking.push({
          searchKey: searchTerm,
          items: res.data[0].items,
        });
      } else this.searchList = res.data[0].items;
      console.log("Initial Search", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingSearch = false;
      this.loadingBookings = false;
    }
  }
}
